import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Link as RouterLink } from "react-router-dom";
import {getPartnerDetails} from 'ultra/helpers/shop';

import ContentImage from '../ContentImage';
import ShopProductPrice from '../ShopProductPrice';
import ShopingCart from '../ShopingCart';

import PreloaderTable from '../../../../Components/PreloaderTable';

import './index.scss'

export default function ShopProductsTable(props) {
    const {links, content, breadcrumbs, showSkeleton} = props

    const {node} = getPartnerDetails(content, breadcrumbs)

    let items = []
    links.order.map(item => {
        items.push(links.list[item])
    })

    if (showSkeleton) return <PreloaderTable/>

    return <>
        {items.length > 0 && <div className="ShopProductsTable">
            {items.map(link => <table key={link._did}>
                <tbody>
                <tr>
                    <td className="details">
                        {link._uri && <RouterLink to={link._uri.slice(1)}>
                            {link.thumbnail && <ContentImage src="thumbnail" key={link} image={link.thumbnail} className="image" />}
                                <span>
                                    {link.title}
                                </span>
                            </RouterLink>
                        }

                        {!link._uri && <>
                            {link.thumbnail && <ContentImage src="thumbnail" key={link} image={link.thumbnail} className="image" />}
                            <span>
                                {link.title}
                            </span>
                        </>}

                        {/* currency={node.partner.config.currency}  */}
                        <ShopProductPrice product={link} />
                    </td>
                    <td className="button">
                        <ShopingCart
                            item={link}
                            shop={node}
                            submitButton={<AddShoppingCartIcon />}
                            fullWidth
                        />
                    </td>
                </tr>
                </tbody>
            </table>)}
        </div>}
    </>
}
