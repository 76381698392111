import Chip from '@mui/material/Chip';
import {CurrencyFormat} from 'ultra/helpers/utils';
import {totalPrice} from "ultra/helpers/shop";

import Button from '@mui/material/Button';

import RequestDetails from '../../../Components/RequestDetails';
import RequestTitle from '../../../Components/RequestTitle';

import {useGlobalCustomContext} from '../../../../../../../Helpers/context';
import {isYoursAccount} from '../../../../../../../Helpers/user';

export default function Template(props) {
    const {code, values, short} = props;

    const {userState} = useGlobalCustomContext();
    const initByCurrentUser = isYoursAccount(userState, values._init_by);

    if (initByCurrentUser) {
        if (short) {
            return <>
                {/* <div className="RequestTitle"><RequestTitle values={values} code={code} /></div> */}

                {/* {code === NOTIFY_INSURANCE.REQUEST && <div>Заявка на страхування</div>}
                {code === NOTIFY_INSURANCE.SUPPORT && <div>Заявка на підтримку</div>} */}
                {/* <div><RequestTitle values={values} code={code} /></div>
                <div>{values.details.result}</div>
                <div className="NotificationActions">
                    <Chip variant="outlined" label="Статус" />
                    <RequestDetails values={values} details={values.details.data_text}/>
                </div> */}
                <div>Ви оформили замовлення</div>
            </>
        }
        else {
            return <>
                    {/* <div className="RequestTitle"><RequestTitle values={values} code={code} /></div> */}

                    {/* {code === NOTIFY_INSURANCE.REQUEST && <div>Заявка на страхування</div>}
                    {code === NOTIFY_INSURANCE.SUPPORT && <div>Заявка на підтримку</div>} */}
                    {/* <div><RequestTitle values={values} code={code} /></div> */}
                    <div>
                        {/* <div>
                            <Chip variant="outlined" label="Статус" />
                        </div> */}
                        {/* {values.details.result} */}
                        Ви оформили нове замовлення на суму 1 000 000 IDR
                        <div>
                            <Button variant='outlined' size='small'>Відмінити</Button>
                        </div>
                    </div>
                    <div className="NotificationActions">
                        {/* <Chip variant="outlined" label="Статус" /> */}
                        <RequestDetails values={values} details={values.details.data_text}/>
                    </div>

                </>
        }
    }

    if (short) {
        return <>
            <div>Нове замовлення</div>
            {/* <div className="NotificationActions"> */}
                {/* <div>{values.details.result}</div> */}
                {/* <Chip variant="outlined" label="Статус" /> */}
                {/* <RequestDetails values={values} details={values.details.data_text}/> */}
                {/* Відправлено: ... */}
                {/* Деталі */}
            {/* </div> */}
        </>
    }
    else {
        return <>
            <div>
                <div>
                    Ви отримали нове замовлення на суму 1 000 000 IDR
                </div>
                <div>
                    <Button variant='outlined' size='small'>Уточнити</Button>
                </div>
            </div>
            {/* <p> */}
                {/* values.shop_currency */}
                {/* {totalPrice(values.details.data.order)} */}
            {/* </p> */}
            <div className="NotificationActions">
                {/* <div>{values.details.result}</div> */}
                <RequestDetails values={values} details={values.details.data_text}/>
                {/* Відправлено: ... */}
            </div>
        </>
    }
}
