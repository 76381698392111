import React, {useReducer, useEffect} from 'react';

import {Outlet, useLoaderData, useNavigate, useNavigation, useLocation} from "react-router-dom";

import {restorePath, getParentPath, normalizeRoute} from 'ultra/helpers/route';
import {isEmptyObj} from 'ultra/helpers/utils';

import AppLayout from '../AppLayout';
import AuthWrap from '../AuthWrap';

import {useConfigStore} from '../../../../Stores/config';
import {useNavigationStore} from '../../../../Stores/navigation';
import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';
import {useToasterStore} from '../../../../Stores/toster';

import GlobalCustomContext from '../../../../Helpers/context'

import {NODE_ACTIONS} from '../../Reducers/node';
import NodeReducer, {createNodeInitialState} from '../../Reducers/node';
import ShopReducer, {createShopInitialState} from '../../Reducers/shop';

import UserReducer, {createUserInitialState} from '../../../../Modules/Profile/Reducers/user';

import {setBodyClass} from '../../../../Helpers/router';

import {useVirtualUriStore} from '../../../../Stores/url';

import './index.scss'

const AuthOnly = () => {
  return <div className='AuthOnlyPage'>
    <p>Цей функціонал доступний лише для зареєстрованих користувачів</p>
    <p>Щоб отримати доступ до всіх можливостей системи, <a href="/ultra/loginAndBack">увійдіть</a> або <a href="/profile/register">зареєструйтесь</a></p>
  </div>
}

const AppWrap = (props) => {
  const data = useLoaderData();

  const {configs, setConfig} = useConfigStore();

  const navigation = useNavigation();
  const navigate = useNavigate();

  const {startLoading, stopLoading} = useNavigationStore();
  const {closeToaster} = useToasterStore();
  const {hideBackdropPreloader} = useBackdropPreloaderStore()

  const location = useLocation();

  const {removeVirtualUri} = useVirtualUriStore();

  const {registeredOnly} = props;

  // // IF VIRTUAL KEYBOARD OPEN, IT SHOULD BE MOVED TO TOP FOR IOS
  // const onResize = (e) => {
  //   const VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.75;

  //   if (
  //       (e.target.height * e.target.scale) / window.screen.height <
  //       VIEWPORT_VS_CLIENT_HEIGHT_RATIO
  //   ) {
  //       // window.Telegram.WebApp.showAlert('keyboard is shown');
  //       document.body.classList.add('virtual-keyboard-attached')
  //   }
  //   else {
  //       // window.Telegram.WebApp.showAlert('keyboard is hidden');
  //       document.body.classList.remove('virtual-keyboard-attached')
  //   }
  // };

  useEffect(() => {
    setConfig(data)

  //   // IF VIRTUAL KEYBOARD OPEN, IT SHOULD BE MOVED TO TOP FOR IOS
  //   if (isMobile && getTelegramMode()) {
  //     if (!window.resizeListenerAttached) {
  //         window.visualViewport.addEventListener('resize', onResize);

  //         window.resizeListenerAttached = true
  //     }
  //   }

  //   return () => {
  //       // if (isMobile && getTelegramMode()) {
  //       if (window.resizeListenerAttached) {
  //           window.visualViewport.removeEventListener('resize', onResize);

  //           window.resizeListenerAttached = false;
  //           document.body.classList.remove('virtual-keyboard-attached')
  //       }
  //       // }
  //   };
  }, [])

  useEffect(() => {
    if (navigation.state === 'loading') {
      // show preloader only when pathname was changed
      if (window.location.pathname !== navigation?.location?.pathname) {
        removeVirtualUri();
        startLoading();
      }
    }

    if (navigation.state === 'idle') {
      window.scrollTo(0, 0);
      setBodyClass();

      setTimeout(() => {
          closeToaster();
          stopLoading();
          hideBackdropPreloader();
      }, 200)
    }
  }, [navigation])

  // if (props.publicPage) return props.children

  // reducers ////////////
  const [nodeState, nodeDispatch] = useReducer(NodeReducer, createNodeInitialState());
  const [shopState, shopDispatch] = useReducer(ShopReducer, createShopInitialState());
  const [userState, userDispatch] = useReducer(UserReducer, createUserInitialState());
  
  const providernodeState = {
      nodeState,
      nodeDispatch,
      shopState,
      shopDispatch,
      userState,
      userDispatch,
  }
  ////////////////////////

  useEffect(() => {
    if (nodeState.deletePopupFinished) {
      const parent = restorePath(getParentPath(nodeState.deletePopupFinished.uri));
      const current = restorePath(nodeState.deletePopupFinished.uri);
      const path = normalizeRoute(location.pathname)

      nodeDispatch({type: NODE_ACTIONS.DELETE_CLEAN})

      if (current === path) {
        navigate(parent);
      } else {
        nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
      }
    }
  }, [nodeState])

  return <GlobalCustomContext.Provider value={providernodeState}>
      <AppLayout configs={configs}>
        <AuthWrap {...props}>
          {registeredOnly && isEmptyObj(userState?.user) && <AuthOnly />}
          {((registeredOnly && userState?.user) || (!registeredOnly)) && <Outlet />}
        </AuthWrap>
      </AppLayout>
  </GlobalCustomContext.Provider>
}

export default AppWrap
