import {cloneDeep} from 'lodash';
import {redirect} from "react-router-dom";

import {getPartnerDetails} from "ultra/helpers/shop";
import {normalizeRoute, restorePath} from 'ultra/helpers/route';
import {DEFAULT_CITY} from 'ultra/const/general';
import {getUrl, getClientCity, getClientConfig} from 'ultra/configs/general';

import {updateActiveFilters} from '../Modules/Content/Helpers/filters';

import {getPathByDID} from './content';

export const checkRedirect = (request) => new Promise(async resolve => {
    const url = new URL(request.url);

    // GO TO DID
    let params = url.searchParams;
    // let params = new URL(window.location.href).searchParams;
    let goToDid
    let goToLogin
    let goToOrder
    let goToProfile

    if (params.get("tgWebAppStartParam")) {
        const p = params.get("tgWebAppStartParam");
        const v = p.split('---');

        // ?tgWebAppStartParam=goToDid---default___l35rkAdRWCasblUdjV8U
        if (v[0] === 'goToDid') {
            goToDid = v[1].replace('___', ':');
        }

        // ?tgWebAppStartParam=goToLogin
        else if (v[0] === 'goToLogin') {
            goToLogin = true;
        }
    }
    else {
        if (params.get("goToDid")) {
            goToDid = params.get("goToDid");
        }

        // ?goToLogin=true
        else if (params.get("goToLogin")) {
            goToLogin = true;
        }

        else if (params.get("goToOrder")) {
            goToOrder = params.get("goToOrder");
        }

        else if (params.get("goToProfile")) {
            goToProfile = params.get("goToProfile");
        }
    }

    const {domain} = getClientConfig(window);

    if (goToDid) {
      const linkDetails = goToDid.split(':');
      if (linkDetails.length !== 2) {
        console.error('Wrong link format');
      }
      else {
        const city = linkDetails[0];
        const did = linkDetails[1];

        try {
            const link = await getPathByDID(city, did).promise;
            return resolve(getUrl(domain, city, link))
        }
        catch(e) {}
      }
    }

    if (goToLogin) {
        return resolve(getUrl(domain, DEFAULT_CITY, '/profile/login'))
    }

    if (goToOrder) {}

    if (goToProfile) {}

    resolve(undefined)
})

export const getNodeContentLoader = (controllers, getContent) => async ({request}) => {
    controllers.map(c => {c.abort()});

    const url = new URL(request.url);
    const redirectURL = await checkRedirect(request);
    if (redirectURL) return redirect(redirectURL);

    // GET FILTERS FROM URL AND STORAGE
    let urlFilters = url.searchParams.get("filters");
    if (urlFilters) {
        urlFilters = JSON.parse(urlFilters);
    }

    let savedFilters = sessionStorage.getItem("filters");
    if (savedFilters) {
        savedFilters = JSON.parse(savedFilters);
    }

    // merge filters
    let mergedFilters = savedFilters ? cloneDeep(savedFilters) : [];
    if (urlFilters) {
        urlFilters.map(filter => {
            const i = mergedFilters.findIndex(f => f[0] === filter[0] && f[2] !== filter[2])
            if (i > -1) {
                mergedFilters[i] = filter;
            }
        })
    }

    const actualFilters = []

    const city = getClientCity(window) || DEFAULT_CITY;

    const path = {
        city: city,
        uri: normalizeRoute(url.pathname)
    }

    const {promise, controller} = await getContent(path, url.search)
    controllers.push(controller)

    const loaderPageConfig = await promise

    // get config to check is saved filter present in configuration
    // TODO: better to generate link
    let filterBy, filterByInline
    if (loaderPageConfig) {
        filterBy = loaderPageConfig.page.content.showFilters?.filterBy || loaderPageConfig.page.config.showFilter?.filterBy;
        filterByInline = loaderPageConfig.page.content.showFilters?.filterByInline || loaderPageConfig.page.config.showFilter?.filterByInline;    

        if (filterBy) {
            filterBy.map(filter => {
                let r = mergedFilters.find(f => f[0] === filter.id);
                if (r) actualFilters.push(r);
            })
        }

        if (filterByInline) {
            filterByInline.map(filter => {
                let r = mergedFilters.find(f => f[0] === filter.id);
                if (r) actualFilters.push(r);
            })
        }
    }

    const {urlShouldBeUpdated, activeFilters} = updateActiveFilters(actualFilters, request.url)

    if (urlShouldBeUpdated && activeFilters.length > 0) {
        const str = JSON.stringify(activeFilters.filter(f => f[0] && f[1]));
        url.searchParams.set('filters', str);

        return redirect(url.pathname + url.search);
    }
    else {
        if (url.pathname.length > 1 && url.pathname[url.pathname.length - 1] === '/') {
          return redirect(url.pathname + url.search);
        }

        const partner = getPartnerDetails({}, loaderPageConfig.breadcrumbs)
        if (loaderPageConfig?.page?.content?._type === 'shop-section' && partner?.node?.virtualCatalogs) {
            return redirect(restorePath(normalizeRoute(partner?.uri)) + '?virtual_uri=' + loaderPageConfig.page.content._uri);
        }

        let virtualUri = url.searchParams.get("virtual_uri");
        if (virtualUri) {
            const {promise, controller} = await getContent({city, uri: restorePath(normalizeRoute(virtualUri))}) // , url.search
            controllers.push(controller)
            const virtualUriLoaderPageConfig = await promise

            loaderPageConfig._virtual_uri = virtualUriLoaderPageConfig;
        }

        return loaderPageConfig;
    }
}
