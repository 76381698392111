import React, {useEffect, useState} from 'react';

import {checkNodeAddRequirements} from 'ultra/helpers/content';
import {normalizeRoute} from "ultra/helpers/route";

import Fab from '@mui/material/Fab';

import MenuItem from '@mui/material/MenuItem';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import HikingOutlinedIcon from '@mui/icons-material/HikingOutlined';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import KitesurfingIcon from '@mui/icons-material/Kitesurfing';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddIcon from '@mui/icons-material/Add';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';

import AdaptiveDialog from '../../../../../../Components/Adaptive/Dialog';

import {useConfigStore} from '../../../../../../Stores/config';
import {useBackdropPreloaderStore} from '../../../../../../Stores/backdropPreloader';
import {useToasterStore} from '../../../../../../Stores/toster';
import {useVirtualUriStore} from '../../../../../../Stores/url';

import {useGlobalCustomContext} from '../../../../../../Helpers/context';
import {getNodeTypeTitle} from '../../../../../../Helpers/content';

import {NODE_ACTIONS} from '../../../../Reducers/node';
import {USER_ACTIONS} from '../../../../../../Modules/Profile/Reducers/user';

function NodeIcon(props) {
    const {node} = props;

    const Icons = {
        _default: ArticleOutlinedIcon,
        node: DescriptionOutlinedIcon,
        networking: HandshakeOutlinedIcon,
        news: NewspaperIcon,
        section: FolderOpenOutlinedIcon,
        wiki: StickyNote2OutlinedIcon,
        'wiki-note': StickyNote2OutlinedIcon,
        alias: ContentCopyIcon,
        recommendation: RateReviewOutlinedIcon,
        location: LocationOnIcon,
        form: DnsOutlinedIcon,
        sale: BallotOutlinedIcon,
        links: TocOutlinedIcon,
        shop: StoreMallDirectoryOutlinedIcon,
        'shop-section': FolderOpenIcon,
        'shop-alias': ContentCopyIcon,
        'shop-product': ShoppingBagOutlinedIcon,
        events: DateRangeOutlinedIcon,
        event: ConfirmationNumberOutlinedIcon,
        tour: HikingOutlinedIcon,
        retreat: SelfImprovementIcon,
        insurance: MedicalServicesOutlinedIcon,
        service: KitesurfingIcon,
        chat: QuestionAnswerOutlinedIcon,
        url: InsertLinkOutlinedIcon,
        topic: ForumOutlinedIcon,
        conversation: MessageOutlinedIcon,
    }

    if (!Icons[node]) {
        return React.createElement(Icons._default)
    }

    return React.createElement(Icons[node])
}


const MenuNodeItem = (props) => {
    const {group, node, i, configs, sorted, childrenOptions, createNode, groupItems} = props;

    const getClass = (i, length) => {
        let className = 'menuItem'

        if (groupItems === 1) {
            className += ' fullWidth'
        }

        if (i === 0) {
            className += ' first'
        }

        if (i === 1) {
            className += ' second'
        }

        if (i + 1 === length) {
            className += ' last'
        }

        return className
    }

    return <MenuItem disableRipple key={node} className={getClass(i, sorted[group].length)}>
        {/* {i === 0 && <span>{group}</span>} */}
        <span className='node' onClick={() => createNode(node, childrenOptions?.[node]?.title || configs.content.nodes[node].title)}>
            <NodeIcon node={node}/>
            <span>{childrenOptions?.[node]?.title || configs.content.nodes[node].title}</span>
        </span>
    </MenuItem>
}


const NodeList = (props) => {
    const {configs, sorted, childrenOptions, createNode} = props;

    const {userState} = useGlobalCustomContext();

    const city = getClientCity(window) || DEFAULT_CITY;

    return <React.Fragment>
        {configs?.content?.groupsOrder?.map(group => sorted[group] && sorted[group]
            ?.filter(node => !userState?.user?.nodes?.singleton?.[city]?.[node])
            ?.map((node, i) => {
                return <MenuNodeItem
                    key={`nodes_list_item_${node}`}
                    configs={configs}
                    sorted={sorted}
                    childrenOptions={childrenOptions}
                    group={group}
                    node={node}
                    i={i}
                    createNode={createNode} 
                    groupItems={sorted[group].length}
                />
            }))}

        {sorted['child']
            ?.filter(node => !userState?.user?.nodes?.singleton?.[city]?.[node])
            ?.map((node, i) => {
                return <MenuNodeItem
                    key={`nodes_list_item_${node}`}
                    configs={configs}
                    sorted={sorted}
                    childrenOptions={childrenOptions}
                    group='child'
                    node={node}
                    i={i}
                    createNode={createNode}
                    groupItems={sorted['child'].length}
                />
            })}
    </React.Fragment>
}


export default function AddNodeButton(props) {
    const {nodes, identifiedNodes, childrenOptions, disabled, parent, closeAction} = props // type

    const {configs} = useConfigStore();
    const {userState} = useGlobalCustomContext();
    const {virtualUri} = useVirtualUriStore();

    const {showError, showPromt, closeToaster} = useToasterStore();
    const {hideBackdropPreloader} = useBackdropPreloaderStore();
    const {nodeDispatch, userDispatch} = useGlobalCustomContext();

    const [allNodes, setAllNodes] = useState([]);
    const [sorted, setSorted] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        function onlyUnique(value, index, array) {
            return array.indexOf(value) === index;
        }

        const all = nodes.concat(identifiedNodes)

        setAllNodes(all.filter(onlyUnique))
    }, [nodes, identifiedNodes])

    useEffect(() => {
        const list = {};

        allNodes.map(node => {
            if (configs?.content?.nodes[node]?.parent) {
                configs?.content?.nodes[node]?.parent.map(parentNode => {
                    const group = 'child';
                    list[group] = list[group] || [];

                    if (!list[group].includes(node))
                        list[group].push(node);
                })
            }
            else {
                const group = configs?.content?.nodes[node]?.group;
                list[group] = list[group] || [];
                list[group].push(node);
            }
        })

        setSorted(list)
    }, [allNodes])

    const showLoginPromt = (title) => {
        showPromt({
            snackbarMessage: `${getNodeTypeTitle(title, null, true)} може бути додана лише після авторизації`,
            onApproveHandler: () => {
                closeToaster();
                hideBackdropPreloader();
    
                userDispatch({type: USER_ACTIONS.LOGIN})
            },
            onCloseHandler: () => {
                closeToaster();
                hideBackdropPreloader();
            }
        })
    }

    const createNodeHandler = (type, title) => {
        closeAction();

        // only for identified users
        if (identifiedNodes.includes(type)) {
            showLoginPromt(title);
            return;
        }

        // create handler
        createNode(type, title)
    }

    function handleAddClick() {
        if (disabled) return;

        setOpen(true)
    };

    function handleAddClose() {
        setOpen(false)
    };

    function createNode(type, title) {
        const addRequirements = checkNodeAddRequirements(configs?.content?.nodes?.[type]?.requirements, {user: userState?.user})
        if (addRequirements?.length > 0) {
            
            showError({snackbarMessage: <div>
                <p> {getNodeTypeTitle(title, 'Щоб додати нову ')} вам необхідно:</p>
                <ul>
                    {addRequirements.map(r => <li>{r}</li>)}
                </ul>
            </div>})
            
            return;
        }

        setOpen(false);

        nodeDispatch({type: NODE_ACTIONS.ADD, data: {
            parent: virtualUri ? normalizeRoute(virtualUri) : parent,
            type
        }});
    }

    if (allNodes.length === 0) return null

    if (allNodes.length === 1) {
        const city = getClientCity(window) || DEFAULT_CITY;
        if (userState?.user?.nodes?.singleton?.[city]?.[allNodes[0]]) return <></>

        return <Fab
            className='AddNodeButton'
            color="contrast"
            onClick={() => createNodeHandler(allNodes[0], childrenOptions?.[allNodes[0]]?.title || configs?.content?.nodes?.[allNodes[0]]?.title)}>
                <AddIcon />
        </Fab>
    }

    return <>
        <Fab
            className='AddNodeButton'
            color="neutral"
            onClick={handleAddClick}>
                <AddIcon />
        </Fab>

        <AdaptiveDialog open={open} onClose={handleAddClose} title="Додати сторінку">
            <ul className="addContentIconMenu columns">
                <NodeList childrenOptions={childrenOptions} createNode={createNodeHandler} configs={configs} sorted={sorted} />
            </ul>
        </AdaptiveDialog>
    </>
}
