import {isMobile} from "react-device-detect";

import {isUndefined} from 'lodash';

import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import {TILE_SIZES} from '../../Helpers/preloaders';

export default function PreloaderLinksTileWithCategories(props) {
    const {src, spacing, xs, md} = props;

    const categories = [1, 2];
    const byCategories = {
        1: [
            1,2,3,4
        ],
        2:[
            1,2,3,4
        ], 
    };

    const type = (src || 'thumbnail').toUpperCase()
    const device = isMobile ? 'MOBILE' : 'DESKTOP'

    return <div className="LinksTile LinksTileWithCategories">
        {categories.map(category =>
            <Grid className="CategoryWrap" container spacing={isUndefined(spacing) ? 2 : 0} key={category}>
                {Object.keys(byCategories[category]).map(link =>
                    <Grid item xs={xs} md={md} key={link}>
                        <Skeleton variant="rectangular" width={60} height={20} style={{marginBottom: '1rem'}}/>
                        <Skeleton variant="rectangular" width={'100%'} height={TILE_SIZES?.[type]?.[device]?.['H']} />
                    </Grid>
                )}
            </Grid>
        )}
    </div>
}
