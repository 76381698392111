import Chip from '@mui/material/Chip';

import './index.scss'

export default function ProductAddons(props) {
    const {item} = props;

    if (!item?.addons?.list) return <></>

    return <div className="ProductAddons">
        <div className='label'>{item?.addons?.placeholder || 'Доступні опції'}:</div>
        {item?.addons?.list?.map(addon =>
            <Chip
                key={'product_option_' + addon.id}
                variant='outlined'
                label={addon.title}
            />
        )}
    </div>
}
