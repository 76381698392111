import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from "react-router-dom";

import JsonView from 'react18-json-view'

// import Collapse from '@mui/material/Collapse';

import {getUrl, getClientConfig} from "ultra/configs/general";
import {restorePath} from "ultra/helpers/route";
import {DEFAULT_CITY} from 'ultra/const/general';
import {getClientCity} from 'ultra/configs/general';
import {hasChildrenValidatePermitsByType, isAdmin} from 'ultra/helpers/auth';
import {isEmptyObj} from 'ultra/helpers/utils';
import {normalizeRoute, revertPath} from 'ultra/helpers/route';

import {useNavigationStore} from '../../../../Stores/navigation';
import {useBackdropPreloaderStore} from '../../../../Stores/backdropPreloader';
import {useGlobalCustomContext} from '../../../../Helpers/context';
import {useToasterStore} from '../../../../Stores/toster';
import {useConfigStore} from '../../../../Stores/config';

import CKEditorContent from '../../Widgets/CKEditorContent';

import FormContainer from '../../../../Components/Form/FormContainer';
import PreloaderForm from '../../../../Components/PreloaderForm';
import AdaptiveDialog from '../../../../Components/Adaptive/Dialog';

import {getNodeForm, getConfigs} from '../../../../Helpers/config'
import {getNodeContent, getNodeByDIDContent, getFormByDIDContent, removeContent, validateContent, getNodeTypeTitle} from '../../../../Helpers/content';

import {NODE_ACTIONS} from '../../Reducers/node';
import {USER_ACTIONS} from '../../../Profile/Reducers/user';
import {removeContentUnusedFiles} from '../../../Profile/Helpers/content';

import './index.scss'

function NodeAdd(props) {
    const {pageConfig, customConfig, afterSubmit, formActions} = props;
  
    const {userState} = useGlobalCustomContext();

    return <>
        <div className='NodeContent'>
            {isAdmin(userState?.user)
                && customConfig && <div className='pathValues'>
                        <div className="label">Path mask values:</div>
                        <JsonView
                            src={customConfig}
                            theme='a11y'
                            editable={false}
                            collapsed={false}
                            enableClipboard={false}
                            collapseStringsAfterLength={6}
                        />
                    </div>
            }

            <FormContainer
                formActions={formActions}
                config={pageConfig?.config}
                afterSubmit={afterSubmit}
            />
        </div>
    </>
}
  
function NodeEdit(props) {
    const {pageConfig, customConfig, afterSubmit, formActions} = props;
  
    const {userState} = useGlobalCustomContext();

    if (pageConfig?.form?.form) {
        pageConfig.form.form.submitText = 'Оновити'
    }

    return <>
        <div className='NodeContent'>
            {isAdmin(userState?.user)
                && customConfig && <div className='pathValues'>
                    <div className="label">Path mask values:</div>
                    <JsonView
                        src={customConfig}
                        theme='a11y'
                        editable={false}
                        collapsed={false}
                        enableClipboard={false}
                        collapseStringsAfterLength={6}
                    />
                </div>
            }

            <FormContainer
                isEditMode={true}
                config={pageConfig?.form}
                content={pageConfig?.page?.content}
                afterSubmit={afterSubmit}
                formActions={formActions}
            />
        </div>
    </>
}

export default function NodePopups() {
    const navigate = useNavigate();
    const location = useLocation();

    const {userState} = useGlobalCustomContext();
    const {setConfig} = useConfigStore();
    const {stopLoading} = useNavigationStore();
    const {showPromt, showError, closeToaster} = useToasterStore();
    const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()
    const {nodeState, nodeDispatch, userDispatch} = useGlobalCustomContext();

    const [pageConfig, setPageConfig] = useState();

    const [isConfigLoading, setIsConfigLoading] = useState(false);

    const formActions = {
        disable: function () {
            showBackdropPreloader()
        },
        enable: function () {
            hideBackdropPreloader()
        }
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Escape') {
                event.preventDefault();

                nodeDispatch({type: NODE_ACTIONS.POPUPS_CLOSE});
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [])

    useEffect(() => {
        if (nodeState) {

            if (nodeState.popupFormOpen) {
                setIsConfigLoading(true)
                setPageConfig({})

                getFormByDIDContent(nodeState.popupFormOpen)
                    .promise
                    .then(config => {
                        if (!config.form.endpoint) {
                            config.page.content.form.endpoint = '/form/' + nodeState.popupFormOpen.city + '/form/' + config.page.content._uri;
                        }

                        setPageConfig(config)
                        setIsConfigLoading(false)
                    })
            }

            if (nodeState.popupPageOpen) {
                setIsConfigLoading(true)
                setPageConfig({})
                
                getNodeByDIDContent(nodeState.popupPageOpen)
                    .promise
                    .then(config => {
                        setPageConfig(config)
                        setIsConfigLoading(false)
                    })
            }

            if (nodeState.addPopupOpen) {
                setIsConfigLoading(true)
                setPageConfig({})

                const path = {
                    city: getClientCity(window) || DEFAULT_CITY,
                    uri: nodeState.addPopupOpen.parent
                };

                getNodeContent(path)
                    .promise
                    .then(config => {

                        getNodeForm(nodeState.addPopupOpen.type, nodeState.addPopupOpen.parent)
                            .promise
                            .then(formConfig => {
                                if (!hasChildrenValidatePermitsByType(config.permits, nodeState.addPopupOpen.type)) {
                                    formConfig.form.submitNotice = 'Сторінку буде опубліковано після перевірки модератором'
                                }

                                const city = getClientCity(window) || DEFAULT_CITY;
                                formConfig.form.endpoint = formConfig.form.endpoint + '/' + city + '/path' + window.location.pathname;

                                setPageConfig({type: nodeState.addPopupOpen.type, config: formConfig})
                                setIsConfigLoading(false)
                            });  
                    })
            }

            if (nodeState.editPopupOpen) {
                setIsConfigLoading(true)
                setPageConfig({})

                getNodeContent(nodeState.editPopupOpen, '?edit=true')
                    .promise
                    .then(config => {
                        config.form.form.endpoint = config.form.form.endpoint + '/' + nodeState.editPopupOpen.city + '/path' + revertPath(nodeState.editPopupOpen.uri);
                        if (!config.permits.validate && !config.page.content._published) {
                            config.form.form.submitNotice = 'Сторінку буде опубліковано після перевірки модератором'
                        }

                        setPageConfig(config)
                        setIsConfigLoading(false)
                    })
                    .catch(err => {
                        hidePopups();
                        showError({snackbarMessage: `${err.message}. Оновіть сторінку та спробуйте ще раз`});
                    })
            }
        
            if (nodeState.validateStart || nodeState.unvalidateStart) {
                showBackdropPreloader();
        
                nodeDispatch({type: NODE_ACTIONS.VALIDATE_CLEANED})
                nodeDispatch({type: NODE_ACTIONS.UNVALIDATE_CLEANED})
        
                validateContent(nodeState.validateStart || nodeState.unvalidateStart)
                    .then(async () => {
                        nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                    })
                    .catch((err) => {
                        hidePopups();
                        showError({snackbarMessage: err.message});
                    })
            }
        
            if (nodeState.deletePopupOpen) {
                showBackdropPreloader();
        
                // TODO: addd check has childrens or not
                // setHasChildren(pageConfig.children.list && Object.keys(pageConfig.children.list).length > 0)
        
                showPromt({
                    // snackbarMessage: hasChildren ? 'У цієї сторінки є вкладені вузли, видалити всі?' : 'Ви впевнені що бажаєте видалити сторінку?',
                    snackbarMessage: 'Ви впевнені що бажаєте видалити сторінку?',
                    snackbarDuration: 10000,
                    onApproveHandler: onRemoveNodeHandler,
                    onCloseHandler: () => {
                        nodeDispatch({type: NODE_ACTIONS.DELETE_CLEAN})
                        hidePopups();
                    }
                })
            }

            if (nodeState.updatePageContentStart) {
                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});

                hidePopups();
            }
        }
    }, [nodeState])

    const afterSubmit = (mode) => {
        const path = normalizeRoute(location.pathname);

        return ({result}) => new Promise(async resolve => {                
            function successHandler(data) {
                nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START, data})
                setPageConfig(null);

                hidePopups();

                resolve();
            }

            userDispatch({type: USER_ACTIONS.USER_REFRESH_START})

            setConfig(await getConfigs().promise);

            // if after rename current path in the list of changed, should be navigation to new page
            switch(mode) {
                case 'edit':
                    if (result.succesfullyRenamed && result.succesfullyRenamed[path]) {
                        navigate(result.succesfullyRenamed[path])
                        hidePopups();
                    }
                    else {
                        successHandler();
                    }
                    break;

                case 'add':
                    const data = {}
                    if (
                        //  || !hasChildrenValidatePermitsByType(pageConfig.permits, result.page._type)
                        isEmptyObj(userState?.user)
                        && !result.page._published
                    ) {
                        data.not_authorized_content = {...result};
                    }

                    successHandler(data);
                    break;
            }
        })
    }

    const onRemoveNodeHandler = () => {
        closeToaster()

        removeContent(nodeState.deletePopupOpen)
            .then(async () => {
                nodeDispatch({type: NODE_ACTIONS.DELETE_FINISHED})
                // nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                // userDispatch({type: USER_ACTIONS.USER_REFRESH_START})
            })
            .catch((err) => {
                hidePopups();
                showError({snackbarMessage: err.message})
                nodeDispatch({type: NODE_ACTIONS.DELETE_FINISHED})
                // nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_START})
                // userDispatch({type: USER_ACTIONS.USER_REFRESH_START})
        })
    }

    const hidePopups = () => {
        nodeDispatch({type: NODE_ACTIONS.POPUPS_CLOSE});

        // after drawler closed delay to hide preloader
        setTimeout(() => {
            stopLoading();
            hideBackdropPreloader();
        }, 500)
    }

    const onClose = () => {
        hidePopups();

        return new Promise(async (resolve) => {
                const did = pageConfig?.config?.defaultValues?._did || pageConfig?.page?.content?._did;
                const city = nodeState?.editPopupOpen?.city || getClientCity(window) || DEFAULT_CITY;

                await removeContentUnusedFiles(city, did);

                setIsConfigLoading(false);

                setPageConfig(null);

                resolve();
        });
    }

    const goToPage = (pageCity, uri) => {
        const city = getClientCity(window) || DEFAULT_CITY;

        if (pageCity === city) {
            navigate(restorePath(uri))
        }
        else {
            const {domain} = getClientConfig(window);
            window.open(getUrl(domain, pageCity, restorePath(uri)), "_self")
        }

        nodeDispatch({type: NODE_ACTIONS.POPUPS_CLOSE});
    }

    return <>
        <AdaptiveDialog open={Boolean(nodeState?.popupFormOpen)} onClose={hidePopups} title={pageConfig?.page?.content?.title}>
            {/* <Collapse in={Boolean(isConfigLoading)}> */}
                {isConfigLoading && <PreloaderForm />}
            {/* </Collapse> */}

            {/* <Collapse in={Boolean(!isConfigLoading)}> */}
                {!isConfigLoading && nodeState?.popupFormOpen && <FormContainer
                    formActions={formActions}
                    config={pageConfig?.page?.content}
                    afterSubmit={onClose}
                />}
            {/* </Collapse> */}
        </AdaptiveDialog>

        <AdaptiveDialog open={Boolean(nodeState?.popupPageOpen)} onClose={hidePopups} title={pageConfig?.page?.content?.title}>
            {/* <Collapse in={Boolean(isConfigLoading)}> */}
                {isConfigLoading && <PreloaderForm />}
            {/* </Collapse> */}

            {/* <Collapse in={Boolean(!isConfigLoading)}> */}
                {!isConfigLoading && nodeState?.popupPageOpen && <CKEditorContent content={pageConfig?.page?.content?.description} />}
                {pageConfig?.children?.order?.length > 0 && <ul>{pageConfig.children.order.map(i => 
                    pageConfig.children.permits[i].read && <li><span className='pseudoLink' onClick={() => goToPage(pageConfig.city, i)}>{pageConfig.children.list[i].title}</span></li>
                )}</ul>}
            {/* </Collapse> */}
        </AdaptiveDialog>

        <AdaptiveDialog open={Boolean(nodeState?.editPopupOpen)} onClose={onClose} title={getNodeTypeTitle(pageConfig?.form?.title || pageConfig?.form?.form?.title, 'Редагувати')}>
            {/* <Collapse in={Boolean(isConfigLoading)}> */}
                {isConfigLoading && <PreloaderForm />}
            {/* </Collapse> */}

            {/* <Collapse in={Boolean(!isConfigLoading)}> */}
                {!isConfigLoading && nodeState?.editPopupOpen && <NodeEdit
                    onClose={onClose}
                    formActions={formActions}
                    pageConfig={pageConfig}
                    customConfig={pageConfig?.page?.config?.values?.[pageConfig?.page?.content?._type]}
                    currentPage={pageConfig?.page?.content}
                    breadcrumbs={pageConfig?.breadcrumbs}
                    afterSubmit={afterSubmit('edit')}
                />}
            {/* </Collapse> */}
        </AdaptiveDialog>

        <AdaptiveDialog open={Boolean(nodeState?.addPopupOpen)} onClose={onClose} title={getNodeTypeTitle(pageConfig?.config?.title || pageConfig?.config?.form?.title, 'Додати')}>
            {/* <Collapse in={Boolean(isConfigLoading)}> */}
                {isConfigLoading && <PreloaderForm />}
            {/* </Collapse> */}

            {/* <Collapse in={Boolean(!isConfigLoading)}> */}
                {!isConfigLoading && nodeState?.addPopupOpen && <NodeAdd
                    onClose={onClose}
                    formActions={formActions}
                    pageConfig={pageConfig}
                    customConfig={pageConfig?.page?.config?.values?.[pageConfig?.type]}
                    currentPage={pageConfig?.page?.content}
                    breadcrumbs={pageConfig?.breadcrumbs}
                    afterSubmit={afterSubmit('add')}
                />}
            {/* </Collapse> */}
        </AdaptiveDialog>
    </>
}
