import React from 'react';
import {getClientCity} from 'ultra/configs/general';
import Slide from '@mui/material/Slide';

import Fab from '@mui/material/Fab';
import IosShareIcon from '@mui/icons-material/IosShare';

import {DEFAULT_CITY} from 'ultra/const/general';

export default function ShareButton(props) {
    const {did, title, showActions} = props;

    if (!navigator.share) return <></>;

    const handleClick = () => {
        // if (disabled) return;
        // const city = getClientCity(window) || DEFAULT_CITY;
        // nodeDispatch({type: NODE_ACTIONS.DELETE_START, data: {city, uri}});
        // TODO: get bot from DB
        const city = getClientCity(window) || DEFAULT_CITY;
        const command = `goToDid---${city}___${did}`;
        const link = `https://t.me/${'MiyProstirBot'}/${city}?startapp=${command}`;

        navigator.share({
            title: title,
            text: 'Запрошуємо до нашого бота в якому ви зможете знайти необхідну інформацію та сервіси по Балі',
            url: link,
          })
            .then(() => console.log('Shared successfully'))
            .catch((error) => console.error('Sharing failed:', error));
    }

    return <>
        <Slide direction="left" in={!showActions}>
            <Fab
                // className='removeContentIcon'
                // color="neutral"
                onClick={handleClick}>
                    <IosShareIcon />
            </Fab>
        </Slide>
    </>
}
