import React, {useEffect, useState} from 'react';

import Switch from '@mui/material/Switch';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import {transliterate} from 'ultra/helpers/utils';

import {useConfigStore} from '../../../Stores/config';

import CurrencyFormated from '../../../Components/CurrencyFormated'

import Text from '../Text';
import Number from '../Number';

import './index.scss';
import { isMobile } from 'react-device-detect';

export default function ProductOptions(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))

    const [optionTitle, setOptionTitle] = useState();
    const [variantTitle, setVariantTitle] = useState();
    const [variantCorrection, setVariantCorrection] = useState();
    // const [addVariantControls, setAddVariantControls] = useState({});

    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    useEffect(() => {
        setClassName('ProductOptionsField inputEmulation'
            + (isDisabled ? ' disabled' : '')
            + (showErrorText ? ' error' : ''))
    }, [showErrorText])

    const [className, setClassName] = useState('ProductOptionsField inputEmulation'
        + (isDisabled ? ' disabled' : '')
        + (showErrorText ? ' error' : ''));

    const onVariantTitleChange = (e) => {
        setVariantTitle(e.target.value);
    }

    const onVariantCorrectionChange = (e) => {
        setVariantCorrection(e.target.value);
    }

    const onOptionTitleChange = (e) => {
        setOptionTitle(e.target.value);
    }

    const onOptionAvailableChange = (optionId, variantId) => {
        const value = content[field.id] || {};
        value.list[optionId].list[variantId].available = !Boolean(value.list[optionId].list[variantId].available);
        onChange({target: {value}});
    }

    const onOptionPlaceholderChange = (e) => {
        const value = content[field.id] || {};
        value.placeholder = e.target.value;

        onChange({target: {value}})
    }

    const onAddOptionHandle = () => {
        if (!optionTitle) {
            return;
        }

        const id = transliterate(optionTitle);

        if (content?.[field.id]?.list?.[id]) {
            alert('Опція вже доступна')
            return;
        }

        const value = content[field.id] || {};
        value.list = value.list || {}

        value.list[id] = {
            title: optionTitle,
            list: {}
        };

        setOptionTitle();
        onChange({target: {value}})
    }

    const onRemoveOptionHandle = (id) => {
        const value = content[field.id] || {};
        delete value.list[id];
        onChange({target: {value}});
    }

    const onAddVariantHandle = (id) => {
        if (!variantTitle) {
            return;
        }

        const value = content[field.id];

        const vaiantId = transliterate(variantTitle);

        if (content[field.id]?.list?.[id]?.[vaiantId]) {
            alert('Варіант вже додано')
            return;
        }

        value.list = value.list || {}

        value.list[id].list = value.list[id].list || {}
        
        value.list[id].list[vaiantId] = {
            title: variantTitle,
            correction: variantCorrection,
            available: true
        }

        setVariantTitle();
        setVariantCorrection();
        onChange({target: {value}});   
    }

    const onRemoveVariantHandle = (id, variantId) => {
        const value = content[field.id] || {};
        if (value?.list?.[id]) delete value.list[id].list[variantId];
        onChange({target: {value}});
    }

    return <div className={className}>
        <div className='inputLabel'>
            {field.placeholder} {field.required ? '*' : ''}
        </div>

        <Grid container spacing={2} className='multiPriceBlock'>
            <Grid item xs={12}>
                <Grid container>
                    <Text
                        disabled={isDisabled}
                        field={{id: 'placeholder', placeholder: "Підпис"}}
                        content={content[field.id] || {}}
                        onChange={onOptionPlaceholderChange}
                    />
                </Grid>
            </Grid>
            <Grid item xs={9} md={10}>
                <Grid container>
                    <Text
                        disabled={isDisabled}
                        field={{id: 'title', placeholder: "Назва"}}
                        content={{title: optionTitle}}
                        onChange={onOptionTitleChange}
                    />
                </Grid>
            </Grid>
            <Grid item xs={3} md={2}>
                <Button
                    fullWidth
                    variant="outlined"
                    component="label"
                    tabIndex={-1}
                    onClick={onAddOptionHandle}
                >
                    <AddIcon />
                </Button>
            </Grid>
        </Grid>

        {content[field.id] && <>
            {content?.[field.id]?.list && Object.keys(content[field.id]?.list)?.map(id =>
                <Grid container spacing={1} className='optionsTable' key={`options_list_${field.id}_${id}`}>
                    <Grid item xs={9} md={10} className='optionsTableTitle'>
                        {content[field.id]?.list?.[id]?.title}
                    </Grid>
                    <Grid item xs={3} md={2} className='optionsTableAction'>
                        <Button
                            variant="outlined"
                            component="label"
                            tabIndex={-1}
                            size='small'
                            onClick={() => onRemoveOptionHandle(id)}
                        >
                            <ClearIcon />
                        </Button>
                    </Grid>

                    {content?.[field.id]?.list[id]?.list && Object.keys(content[field.id]?.list[id].list)?.map(variantId =>
                        <Grid className='optionListEditItem' key={`options_list_edit_${field.id}_${variantId}`} item xs={12}>
                            <Grid container>
                                {/* <Grid item xs={1}></Grid> */}
                                <Grid item xs={3} md={2}>
                                    <Switch checked={content[field.id]?.list[id].list[variantId].available} onChange={(e) => onOptionAvailableChange(id, variantId)}/>
                                </Grid>
                                <Grid item xs={9} md={5}>
                                    {!content[field.id]?.list[id].list[variantId].available && <s>{content[field.id]?.list[id].list[variantId].title}</s>}
                                    {content[field.id]?.list[id].list[variantId].available && content[field.id]?.list[id].list[variantId].title}
                                </Grid>
                                <Grid item xs={10} md={4}>
                                    {content[field.id]?.list[id].list[variantId].correction && <CurrencyFormated value={content[field.id]?.list[id].list[variantId].correction} currency={content.price.currency}/>}
                                    {!content[field.id]?.list[id].list[variantId].correction && 'free'}
                                </Grid>
                                {!isMobile && <Grid item md={1}>
                                    <ClearIcon className='removeOption' onClick={() => onRemoveVariantHandle(id, variantId)}/>
                                </Grid>}
                                {isMobile && <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        component="label"
                                        tabIndex={-1}
                                        size='small'
                                        onClick={() => onRemoveVariantHandle(id, variantId)}
                                    >
                                        <ClearIcon />
                                        <span>Видалити</span>
                                    </Button>
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <div className="inputEmulation">
                            <span className="inputLabel">Додати</span>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={5}>
                                    <Grid container>
                                        <Text
                                            disabled={isDisabled}
                                            field={{id: 'variant', placeholder: "Варіант"}}
                                            content={{variant: variantTitle}}
                                            onChange={onVariantTitleChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Grid container>
                                        <Number
                                            disabled={isDisabled}
                                            field={{id: 'correction', placeholder: "Корекція ціни"}}
                                            content={{correction: variantCorrection}}
                                            onChange={onVariantCorrectionChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        component="label"
                                        tabIndex={-1}
                                        onClick={() => onAddVariantHandle(id)}
                                    >
                                        <AddIcon />
                                        {isMobile && <span>Додати</span>}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            )}
        </>}
    </div>
}
