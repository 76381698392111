import {getPartnerDetails} from 'ultra/helpers/shop';
import {isArray} from "lodash";
import {isMobile} from "react-device-detect";
// import Skeleton from '@mui/material/Skeleton';

import PreloaderTopPagePicture from '../../../../../../../../Components/PreloaderTopPagePicture';
import PreloaderText from '../../../../../../../../Components/PreloaderText';

import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useConfigStore} from '../../../../../../../../Stores/config';

import ShopProductPrice from '../../../../../../Widgets/ShopProductPrice'
import ShopProductPrices from '../../../../../../Widgets/ShopProductPrices'
import ContentImage from '../../../../../../Widgets/ContentImage'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import ShopingCart from '../../../../../../Widgets/ShopingCart'
import ProductOptions from '../../../../../../Widgets/ProductOptions'
import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import Gallery from '../../../../../../Widgets/Gallery';
import FilterChip from '../../../../../../Widgets/FilterChip'

import './index.scss'

export default function Template(props) {
    const {content, permits, config, form, breadcrumbs} = props

    const {node} = getPartnerDetails(content, breadcrumbs)

    const {contentLoading} = useNavigationStore()
    const {configs} = useConfigStore();

    if (contentLoading) return <>
        <PreloaderTopPagePicture />
        <PreloaderText />
    </>

    return <div className="Service">
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {content.banner && <ContentImage src="banner" image={content.banner} />}

        {/* {contentLoading && <div>
            <Skeleton variant="rectangular" width="100%" height={100} style={{marginBottom: '2rem'}} />
        </div>} */}

        {/* {!contentLoading &&  */}
        {!content.price.prices && <div className={(!content?.price?.free && !content?.price?.flexible) ? "details" : "details detailsOneButton"}>
            {!isMobile && (!content?.price?.free && !content?.price?.flexible) && <div className="labelWrap price">
                <span className="label">Ціна:</span>
                <span className="labelValue">
                    {/* currency={node?.partner?.config?.currency} */}
                    <ShopProductPrice product={content} />
                </span>
            </div>}

            <ShopingCart
                fullWidth
                item={content}
                shop={node}
                showPrice={isMobile}
            />
        </div>}

        {content.price.prices && <div className='multiPrices'><ShopingCart
            fullWidth
            item={content}
            shop={node}
            showPrice={isMobile}
        /></div>}
        {/* } */}

        {/* <ShopProductPrices product={content} /> */}

        <CKEditorContent content={content.description} />

        <ProductOptions item={content}/>

        {isArray(content.category) && content.category.length > 1
            && <div className='categories'>
                {content.category.map(i => <FilterChip key={`service_category_${i}`} value={i} config={configs.content.nodes[content._type].fields.category || form.fields.category} />)}
            </div>
        }

        {content.gallery && <Gallery content={content.gallery} />}
    </div>
}
