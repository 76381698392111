import React, {useEffect, useState} from 'react';

import { NavLink as RouterLink } from "react-router-dom";
import {isMobile} from "react-device-detect";
import {useLoaderData, useNavigation, useParams} from "react-router-dom";

import {normalizeRoute, restorePath} from "ultra/helpers/route";
import {getClientUrl} from 'ultra/configs/general'
import { hasFullAccess } from "ultra/helpers/auth";

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import { setPageTitle } from '../../../../../Helpers/router';
import {useGlobalCustomContext} from '../../../../../Helpers/context';
import {getFormHistory} from '../../../Helpers/history';

import {useConfigStore} from '../../../../../Stores/config';

import PreloaderTable from '../../../../../Components/PreloaderTable';

import UserHistoryLink from '../../../../Content/Widgets/UserHistoryLink'

import TableView from '../../../../Content/Widgets/Table';
import BreadcrumbsWidget from '../../../../Content/Widgets/Breadcrumbs';
import TitleWidget from '../../../../Content/Widgets/Title';
// import FiltersLine from '../../../../Content/Widgets/FiltersLine';
// import NodeInlineActions from '../../../../Content/Widgets/NodeInlineActions';
// import DateValue from '../../../../Content/Widgets/DateValue';
// import UserHistoryLinkByID from '../../../../Content/Widgets/UserHistoryLinkByID';

// import NodeHistoryInlineActions from '../../../Components/NodeHistoryInlineActions';

import {useBackdropPreloaderStore} from '../../../../../Stores/backdropPreloader';
import {useToasterStore} from '../../../../../Stores/toster';

import {NODE_ACTIONS} from '../../../../Content/Reducers/node';

import './index.scss';

export default () => {
    const initHistory = useLoaderData();

    let {partner, form} = useParams();

    const navigation = useNavigation();
    const {configs} = useConfigStore();

    const {showError} = useToasterStore();
    const {nodeState, nodeDispatch} = useGlobalCustomContext();
    const {showBackdropPreloader, hideBackdropPreloader} = useBackdropPreloaderStore()
    const {userState} = useGlobalCustomContext();

    const [history, setHistory] = useState();
    const [tableOptions, setTableOptions] = useState();
    const [config, setConfig] = useState();
    const [permits, setPermits] = useState();

    const baseFields = {
        _timestamp: {
            type: 'timestamp',
            placeholder: 'Дата'
        },
        _oid: {
            type: 'order',
            placeholder: 'Номер заявки',
            extraData: initHistory
        },
        _init_by: {
            type: 'history-user-link',
            placeholder: 'Замовник',
        },
        // _code: {
        //     type: 'notification',
        //     placeholder: 'Тип'
        // },
        data_text: {
            type: 'request',
            placeholder: 'Заявка'
        },
        _city: {
            type: 'city',
            placeholder: 'Місто'
        }
        // data: {
        //     type: 'json',   // history-details
        //     placeholder: 'Дані'
        // }
    }

    const [fields] = useState(baseFields);

    useEffect(() => {
        setTableOptions(isMobile ? {
            visible: ['_init_by'], // , 'code'
            hidden: ['_timestamp', '_city', 'data_text'],
            noHeaders: true
        } : {
            visible: ['_timestamp', '_city', '_init_by'], //  'code', '_oid',
            hidden: ['data_text'],
            // noHeaders: true,
            // unfolded: true,
        })

        // updateHistory(initHistory);

        setPermits({})

        setPageTitle('Історія заявок')
    }, [])

    useEffect(() => {
        if (nodeState.updatePageContentStart) {
            nodeDispatch({type: NODE_ACTIONS.UPDATE_PAGE_CONTENT_END});
            updatePage()
        }
    }, [nodeState])

    useEffect(() => {
        if (navigation.state === 'idle') {
            updateHistory(initHistory);
        }
    }, [navigation])

    useEffect(() => {
        const config = {templateOptions: {}}

        if (configs) {
            setConfig({...config})
        }
    }, [userState?.user, configs])

    function updateHistory(history) {
        setHistory({...history})

        hideBackdropPreloader()
    }

    // const selectClasses = (data) => {
    //     return data?._code
    //         ? 'tr_' + data?._code.replace(':', '_')
    //         : '';
    // }

    const content = {
        title: `${history?.title}`
    }

    const breadcrumbs = {}
    breadcrumbs['\\profile\\partnership'] = {title: "Партнерська панель"}

    const updatePage = () => {
        const url = new URL(window.location.href);

        updateHistory({});
        getFormHistory(partner, form, url.search)
            .promise
            .then(updateHistory)
    }

    return <div id="Content" className='Content'>
            <div className='HistoryFormAdminPage'>
                {history && <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} />}
                {history && <BreadcrumbsWidget hideRoot links={breadcrumbs} hide={content.hideBreadcrumbs}/>}

                {/* {isMobile && <div className='MobileFiltersLine'>
                    <FiltersLine
                        size="small"
                        filters={config?.showFilters?.filterByInline}
                    />
                </div>} */}

                {(!fields) && <PreloaderTable/>}

                {fields && history && <TableView
                    showPreloader={!history?.list}
                    content={history}
                    fields={fields}
                    showPagination={true}
                    tableOptions={tableOptions}
                    // actions={(id) => NodeHistoryInlineActions({
                        // isRemoved: !history?.form?._uri,
                        // content: history?.list?.[id],
                        // unfolded: true
                    // })}
                    // selectClasses={selectClasses}
                />}

                <Grid container spacing={2} className='HistoryFormAdminPageDetails'>
                    {history?.link && <Grid item xs={12} className='labelWrap'>
                        <span className='label'>Посилання:</span>
                        <span className='labelValue'>
                            <span className="shopLink">
                                {/* <RouterLink to={getClientUrl(history?.partner.city, restorePath(normalizeRoute(history.link._uri)))}>{history.link.title}</RouterLink> */}
                                <RouterLink to={getClientUrl(history?.partner.city, restorePath(normalizeRoute(history?.link._uri)))}>{history?.link.title}</RouterLink>
                            </span>
                        </span>
                    </Grid>}
                    {history?.access && <Grid item xs={12} md={6} className='labelWrap'>
                        <span className='label'>Доступ:</span>
                        <span className='labelValue'>
                            {history.access.map(i =>
                                <Chip label={i.displayName ? (hasFullAccess(userState.user) ? <UserHistoryLink id={i.id} name={i.displayName} /> : i.displayName) : i} variant="outlined" />
                            )}
                            {/* <RouterLink to={restorePath(normalizeRoute(history.form._uri))}>{history.form.title}</RouterLink> */}
                        </span>
                    </Grid>}
                    {history?.manager && <Grid item xs={12} md={6} className='labelWrap'>
                        <span className='label'>Менеджер:</span>
                        <span className='labelValue'>
                            <Chip label={<>
                                {history.manager.conversation && <span>
                                    {history.manager.conversation.dialog && history.manager.conversation.private && 'Приватний чат '}
                                    {!history.manager.conversation.dialog && history.manager.conversation.private && 'Публічний чат '}
                                    {!history.manager.conversation.private && !history.manager.conversation.dialog && 'Канал'}
                                </span>}

                                {history?.manager?.topic && <span>Нотифікації у {history.manager.topic}</span>}
                            </>} variant="outlined" />
                            {history.manager.contact && <Chip label={hasFullAccess(userState.user) ? <UserHistoryLink id={history.manager.contact.id} name={history.manager.contact.displayName} /> : history.manager.contact.displayName} variant="outlined" />}
                        </span>
                    </Grid>}
                    {history?.owners && <Grid item xs={12} md={6} className='labelWrap'>
                        <span className='label'>Власник:</span>
                        <span className='labelValue'>
                            {history.owners.map(i => <Chip label={i.displayName || i} variant="outlined" />)}
                            {/* <RouterLink to={restorePath(normalizeRoute(history.form._uri))}>{history.form.title}</RouterLink> */}
                        </span>
                    </Grid>}
                </Grid>

                {/* {history?.form && <NodeInlineActions permits={history?.permits} node={history?.form} hideHistory={true} showCleanHistory unfolded={!isMobile} className="CurrentPageInlineActions" city={city} hasFullAccess={hasFullAccess(userState.user)} />} */}
            </div>
        </div>
}
