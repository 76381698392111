import { create } from 'zustand'

export const useVirtualUriStore = create((set) => ({
  virtualUri: null,
  setVirtualUri: (uri) => set(() => {
    return {virtualUri: uri}
  }),
  removeVirtualUri: () => set(() => {
    return {virtualUri: null}
  }),
}))
