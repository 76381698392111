import CurrencyFormated from '../../../../Components/CurrencyFormated'

import './index.scss';

export default function ShopProductPrice(props) {
    const {product, selectedPrice, addons, options} = props;

    if (!product) return <></>

    if (product?.price?.flexible) return <></>
    if (product?.price?.free) return <>Безкоштовно</>

    let optionsAdditionalPrice = 0;
    if (options) {
        Object.keys(options).map(option => {
            optionsAdditionalPrice += product?.options?.list?.[option]?.list[options[option]]?.correction
        })
    }

    let addonsAdditionalPrice = 0;
    if (addons?.length > 0) {
        addons.map(addon => {
            addonsAdditionalPrice += addon?.quantity ? addon?.quantity * addon?.price : addon?.price
        })
    }

    if (selectedPrice) {
        let price = product.price.prices.find(i => i.id === selectedPrice) || {};

        if (price?.free) return <>Безкоштовно</>

        return <CurrencyFormated
            value={Number(price.price) + Number(optionsAdditionalPrice) + Number(addonsAdditionalPrice)}
            currency={product.price.currency}
        />
    }
    else if (product?.price?.prices) {
        return <></>
    }
    else {
        if (product.price.discount) {
            return <span className="ShopProductPrice">
                <span className='priceValue'>
                    <span className='actualPrice'>
                        <CurrencyFormated value={Number(product.price.price) - Number(product.price.discount) + Number(optionsAdditionalPrice) + Number(addonsAdditionalPrice)} currency={product.price.currency} />
                    </span>
                    <span className='oldPrice'>
                        <CurrencyFormated value={Number(product.price.price) + Number(optionsAdditionalPrice) + Number(addonsAdditionalPrice)} currency={product.price.currency} />
                    </span>
                </span>

                {product.price.prefix && <span className='pricePrefix'>/ {product.price.prefix}</span>}
            </span>
        }
        else {
            return <span className="ShopProductPrice">
                <span className='priceValue'>
                    <CurrencyFormated value={Number(product.price.price) + Number(optionsAdditionalPrice) + Number(addonsAdditionalPrice)} currency={product.price.currency} />
                </span>
                {product.price.prefix && <span className='pricePrefix'>/ {product.price.prefix}</span>}
            </span>
        }
    }
}
