import Chip from '@mui/material/Chip';
import {CurrencyFormat} from 'ultra/helpers/utils';

import './index.scss'

export default function ProductOptions(props) {
    const {item} = props;

    if (!item?.options?.list) return <></>

    return <div className="ProductOptions">
        <div className='label'>{item?.options?.placeholder || 'Доступні опції'}:</div>
        {Object.keys(item.options.list).map(option => <div className='labelWrap' key={'product_option_' + option}>
            <span className='label'>{item.options.list[option].title}:</span>
            <span className='labelValue'>
                {Object.keys(item.options.list[option].list).map(i => <Chip
                    key={'product_option_' + option + '_' + i}
                    variant='outlined'
                    className={!item.options.list[option].list[i].available ? 'notAvailableOption' : ''}
                    label={<><span>{item.options.list[option].list[i].title}</span> {item.options.list[option].list[i].correction ?
                        <span className='label'>({(item.options.list[option].list[i].correction > 0 ? '+' : '-') + CurrencyFormat(item.options.list[option].list[i].correction, item.price.currency)})</span>
                        : ''}
                    </>}
                />)}
            </span>
        </div>)}
    </div>
}
