import {useEffect, useState} from "react";

import {isMobile} from "react-device-detect";

// import {getClientCity} from 'ultra/configs/general';
import {getPartnerDetails} from 'ultra/helpers/shop';

import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useConfigStore} from '../../../../../../../../Stores/config';

import PreloaderText from '../../../../../../../../Components/PreloaderText';

import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import ShopingCart from '../../../../../../Widgets/ShopingCart'
import ContentImage from '../../../../../../Widgets/ContentImage'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
// import ShopCategoryLabel from '../../../../../../Widgets/ShopCategoryLabel'
import ShopProductPrice from '../../../../../../Widgets/ShopProductPrice'
import ShopProductPrices from '../../../../../../Widgets/ShopProductPrices'
import FilterChip from '../../../../../../Widgets/FilterChip'

// import {getFilter} from '../../../../../../../../Helpers/filters'

import './index.scss'

export default function Template(props) {
    const {content, permits, config, breadcrumbs} = props

    // const [categoriesSettings, setCategoriesSettings] = useState()
    const [shop, setShop] = useState()

    const {configs} = useConfigStore();

    const formatSize = (value) => {
        return value.join(', ');
    }

    useEffect(() => {
        const shopData = getPartnerDetails(content, breadcrumbs)?.node;

        // if (shopData?.categories && shopData?.categories?.source) {
            // const city = getClientCity(window);
            // const config = getFilter(configs.filters, city, shopData.categories.source);
            // setCategoriesSettings(config);
        // }

        setShop(shopData);
    }, [])

    const {contentLoading} = useNavigationStore()

    if (contentLoading || !shop) {
        return <div className="Product">
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <div className='photoWrap'>
                        <Skeleton variant="rectangular" width="100%" height={isMobile ? 300 : 400} style={{}} />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <PreloaderText />
                </Grid>
            </Grid>
        </div>    
    }

    return <div className="Product">
        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <div className='photoWrap'>
                    <ContentImage src="thumbnail" image={content.thumbnail} className="photo" />
                    {content.status && <div className="photoLabel">
                        <FilterChip value={content.status} config={configs.content.nodes['shop-product'].fields.status} />
                    </div>}
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle}/>
                <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

                {/* <ShopCategoryLabel categories={categoriesSettings} category={content.category}/> */}

                {/* currency={shop?.partner?.config?.currency}  */}
                <ShopProductPrice product={content} />

                <ShopingCart
                    item={content}
                    shop={shop}
                    fullWidth
                />

                {content['jewelry-size'] && content['jewelry-size'].length > 0 && <div className='ProductСharacteristics'>
                    <div className='labelWrap'>
                        <span className="label">Доступні розміри:</span>
                        <span className="labelValue">{formatSize(content['jewelry-size'])}</span>
                    </div>
                </div>}

                {/* <ShopProductPrices product={content} /> */}

                <CKEditorContent content={content.description} />
            </Grid>
        </Grid>
    </div>
}
