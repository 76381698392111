import {useState} from 'react';

import Skeleton from '@mui/material/Skeleton';

import ShoppingCardButton from './Buttons/ShoppingCardButton'
import GlobalSearchNodeButton from './Buttons/GlobalSearchNodeButton'
import NodeManipulationsButton from './Buttons/NodeManipulationsButton'
import ShareButton from './Buttons/ShareButton'

import {useNavigationStore} from '../../../../Stores/navigation';
import {useGlobalCustomContext} from '../../../../Helpers/context';

import './index.scss'

export default function NodeActions(props) {
    const {config, content} = props;

    const [showActions, setShowActions] = useState(false);

    const {contentLoading} = useNavigationStore();
    const {userState} = useGlobalCustomContext();

    if (userState?.userLoginInProgress) return <div className='actionsList'>
        <div className='actionsListButtons'>
            <Skeleton variant="rectangular" width={56} height={56} style={{
                borderRadius: '15px',
                display: 'inline-flex',
                marginLeft: '0.5rem'
            }} />
        </div>
    </div>

    return <div className='actionsList'>
        <div className='actionsListButtons'>
            <ShoppingCardButton disabled={contentLoading} showActions={showActions}/>

            <ShareButton did={content._did} title={content.title} showActions={showActions}/>

            <GlobalSearchNodeButton config={config.page?.config} children={config.children} disabled={contentLoading} showActions={showActions}/>

            <NodeManipulationsButton config={config} content={content} onOpen={setShowActions} />
        </div>
    </div>
}
