import React, { useEffect, useState } from 'react';
import {isEqual} from 'lodash';
import {useNavigate} from "react-router-dom";
import {NavLink as RouterLink} from "react-router-dom";
import {isMobile} from "react-device-detect";

import {isEmptyObj} from 'ultra/helpers/utils';
import {normalizeRoute, restorePath} from "ultra/helpers/route";
import {totalPrice} from "ultra/helpers/shop";
import {attachUserData} from 'ultra/helpers/auth';
import {CurrencyFormat} from 'ultra/helpers/utils';

import Chip from '@mui/material/Chip';
import Slide from '@mui/material/Slide';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';

import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import Fab from '@mui/material/Fab';

import Collapse from '@mui/material/Collapse';

import {useShoppingStore} from '../../../../../../Stores/shopping';
import {useConfigStore} from '../../../../../../Stores/config';
import {useToasterStore} from '../../../../../../Stores/toster';

import {getContentShopsConfig} from '../../../../Helpers/shops';
import {useGlobalCustomContext} from '../../../../../../Helpers/context';

import Preloader from '../../../../../../Components/Preloader';
import FormContainer from '../../../../../../Components/Form/FormContainer';
import CurrencyFormated from '../../../../../../Components/CurrencyFormated';
import AdaptiveDialog from '../../../../../../Components/Adaptive/Dialog';

import ShopProductPrice from '../../../ShopProductPrice';
// import DefaultImage from '../../../DefaultImage';
import ContentImage from '../../../ContentImage';

import {SHOP_ACTIONS} from '../../../../Reducers/shop';

import './index.scss'
import PartnerImage from '../../../PartnerImage';

const orderByTitle = (a, b) => a.details?.title?.localeCompare(b.details.title);
const orderProducts = (a, b) => {
    let aId = a.details?.title;
    let bId = b.details.title;

    aId += '_' + a.selectedPrice + '_' + (a.options ? JSON.stringify(a.options) : 'no') + '_' + (a.flexible ? JSON.stringify(a.flexible) : 'no');
    bId += '_' + b.selectedPrice + '_' + (b.options ? JSON.stringify(b.options) : 'no') + '_' + (b.flexible ? JSON.stringify(b.flexible) : 'no');

    return aId?.localeCompare(bId);
};

function ShortOrderDescription(props) {
    const {orders} = props;
    if (!orders || orders.length === 0) return null

    return <div className='ShortOrderDescription'>
        <div>Ваше замовлення:</div>
        {orders.map(order => <div key={order.details.id}>
            - <RouterLink to={restorePath(normalizeRoute(order.details._uri))}>{order.details.title}</RouterLink>

            {order.selectedPrice && <> ({order.details.price.placeholder?.toLowerCase()}: {order.details.price.prices.find(i => i.id === order.selectedPrice)?.title})</>}

            &nbsp;- {order.amount}
        </div>)}
    </div>
}

function EditFlexible(props) {
    const {values, settings, onChange} = props
    const [form, setForm] = useState({})

    useEffect(() => {
        const form = {}
        form.fields = {}

        if (settings) {
            Object.keys(settings).map(id => {
                form.fields[id] = {
                    type: "textarea",
                    placeholder: settings[id],
                    required: true,
                    options: {
                        minRows: 1
                    }
                }
            })

            setForm(form);
        }
    }, [])

    if (isEmptyObj(settings)) return <></>
    if (isEmptyObj(form)) return <></>

    return <Grid item xs={12}>
        <FormContainer
            config={form}
            content={values || {}}
            onChange={onChange}
        />
    </Grid>
}

function EditOptions(props) {
    const {values, product, onChange} = props

    const [editOptions, setEditOptions] = useState()

    useEffect(() => {
        if (values) {
            setEditOptions(values)
        }
        else {
            setEditOptions({})
        }
    }, [])

    const onChangeEditProductOption = (option, value) => {
        const v = {...editOptions};
        v[option] = value;
        setEditOptions(v);
        onChange(v);
    }

    if (!product?.options?.list || !editOptions) return <></>

    return Object.keys(product.options.list)
        .map(option =>
            <Grid item xs={12} key={'product_options_' + option}>
                <FormControl fullWidth>
                    <InputLabel>{product.options.list[option].title}</InputLabel>
                    <Select
                        required={true}
                        label={product.options.list[option].title}
                        defaultValue=""
                        value={editOptions[option] || ""}
                        onChange={(e) => onChangeEditProductOption(option, e.target.value)}
                    >
                        <MenuItem value="">&nbsp;</MenuItem>
                        {Object.keys(product.options.list[option].list)
                            .filter(i => product?.options.list[option].list[i].available)
                            .map(i => <MenuItem key={i} value={i}>
                                <span>{product.options.list[option].list[i].title}</span>
                                &nbsp;&nbsp;&nbsp;
                                <span className='productOptionAdditionalPrice'>
                                    {product.options.list[option].list[i].correction > 0 && <>+</>}
                                    {product.options.list[option].list[i].correction < 0 && <>-</>}
                                    {product.options.list[option].list[i].correction !== 0 ? 
                                        CurrencyFormat(product.options.list[option].list[i].correction, product.price.currency)
                                        : ''
                                    }
                                </span>
                            </MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
        )
}

function EditAddons(props) {
    const {values, addons, currency, onChange} = props;

    const [addonsQuantity, setAddonsQuantity] = useState()
    const [addonsChecked, setAddonsChecked] = useState()

    useEffect(() => {
        const checked = {}
        const quantity = {}

        addons?.list.map(addon => {
            checked[addon.id] = false
        })

        if (values) {
            values.map(value => {
                checked[value.id] = true
                quantity[value.id] = value.quantity
            })
        }

        setAddonsChecked(checked)
        setAddonsQuantity(quantity)
    }, [])

    function getAddons(checked, quantity) {
        const result = [];

        Object.keys(checked)
            .filter(addon => checked[addon])
            .map(addon => {
                const item = addons?.list?.find(a => a.id === addon)
                if (item) {
                    item.quantity = quantity[addon]
                    result.push({...item})
                }
            })

        return result;
    }

    const handleAddOption = (id) => {
        let values = {...addonsQuantity}
        if (!values[id]) {
            values[id] = 1
        }

        values[id] = values[id] + 1
        setAddonsQuantity(values)

        onChange(getAddons(addonsChecked, values))
    }

    const handleRemoveOption = (id) => {
        let checked = {...addonsChecked}
        let values = {...addonsQuantity}

        if (values[id] === 1) {
            delete checked[id];
            delete values[id];

            setAddonsQuantity(values);
            setAddonsChecked(checked);

            onChange(getAddons(checked, values));
            return;
        }

        if (!values[id]) {
            values[id] = 1
        }

        values[id] = values[id] - 1
        setAddonsQuantity(values)

        onChange(getAddons(checked, values))
    }

    const handleAddonChecked = (id) => {
        let checked = {...addonsChecked}
        let quantity = {...addonsQuantity}

        checked[id] = !Boolean(checked[id])
        setAddonsChecked(checked)

        if (checked[id]) quantity[id] = 1
        setAddonsQuantity(quantity)

        onChange(getAddons(checked, quantity))
    }

    if (!addonsChecked) return <></>

    return <>
        {addons?.placeholder && <Grid item xs={12}>{addons?.placeholder}:</Grid>}
        {addons?.placeholder && <Grid item xs={12} className='productAddonsSelectList'>
            {addons?.list?.map(addon => <Grid container key={'product_addons_' + addon.id}>
                <Grid item xs={9} md={6} className='currencyTitle'>
                    {/* onChange={() => handleAddonChecked(addon.id)} */}
                    <FormControlLabel
                        onChange={() => handleAddonChecked(addon.id)}
                        control={
                            <Checkbox checked={addonsChecked[addon.id]} />
                        }
                        label={
                            <span className="title">{addon.title}</span>
                        }
                    />
                </Grid>

                {isMobile && <Grid item xs={3} md={3} className='quantityCell'>
                    {addonsChecked[addon.id] && addon.counter && <div className='quantity'>
                        <div className='wrap'>
                            <RemoveCircleOutlineIcon className='btn' onClick={() => handleRemoveOption(addon.id)}/>
                            {addonsQuantity[addon.id]}
                            <AddCircleOutlineIcon className='btn' onClick={() => handleAddOption(addon.id)}/>
                        </div>
                    </div>}
                </Grid>}

                <Grid item xs={9} md={3} className='currencyPrice'>
                    <CurrencyFormated value={addon.price} currency={currency} />
                </Grid>

                {!isMobile && <Grid item xs={3} md={3} className='quantityCell'>
                    {addonsChecked[addon.id] && addon.counter && <div className='quantity'>
                        <div className='wrap'>
                            <RemoveCircleOutlineIcon className='btn' onClick={() => handleRemoveOption(addon.id)}/>
                            {addonsQuantity[addon.id]}
                            <AddCircleOutlineIcon className='btn' onClick={() => handleAddOption(addon.id)}/>
                        </div>
                    </div>}
                </Grid>}

            </Grid>)}
        </Grid>}
    </>
}

export default function ShoppingCardButton(props) {
    const {disabled, showActions} = props;
    const {cart, addProduct, updateProduct} = useShoppingStore()
    const {configs, setConfig} = useConfigStore();
    const [isLoading, setIsLoading] = useState(false)

    const [shops, setShop] = useState([])

    const [options, setOptions] = useState(null)
    const [addons, setAddons] = useState(null)
    const [flexible, setFlexible] = useState(null)

    const {showError} = useToasterStore();
    const {shopState, shopDispatch} = useGlobalCustomContext();

    useEffect(() => {
        let updatedShops = []

        // get stores
        cart.map(item => {
            if (updatedShops.find(i => i.details.id === item.shop.id)) return;
            updatedShops.push({details: {...item.shop}})
        })

        // get items
        cart.map(item => {
            const shopIndex = updatedShops.findIndex(i => i.details.id === item.shop.id)
            updatedShops[shopIndex].items = updatedShops[shopIndex].items || []

            const itemIndex = updatedShops[shopIndex].items.findIndex(i =>
                i.details.id === item.product.id
                && i.selectedPrice === item.selectedPrice
                && isEqual(i.options, item.options)
                && isEqual(i.addons, item.addons)
                && isEqual(i.flexible, item.flexible)
            )

            if (itemIndex > -1) {
                updatedShops[shopIndex].items[itemIndex].amount += 1
            }
            else {
                updatedShops[shopIndex].items.push({
                    details: {...item.product},
                    selectedPrice: item.selectedPrice,
                    options: item.options,
                    addons: item.addons,
                    flexible: item.flexible,
                    amount: 1
                })
            }
        })

        // sort
        updatedShops = updatedShops.sort(orderByTitle)
        updatedShops.map(i => {
            i.items = [...i.items.sort(orderByTitle)]
        })

        setShop(updatedShops)
    }, [cart])

    useEffect(() => {
        // get shops config, if it's not present
        if (!configs.shop && cart.length > 0) {
            setIsLoading(true)
            getContentShopsConfig()
                .promise
                .then(shopConfig => {
                    const newConfig = {...configs};
                    newConfig.shop = shopConfig;
                    setConfig(newConfig);
                    setIsLoading(false);
                })
        }
    }, [shopState?.openCard])

    const handleClose = () => {
        shopDispatch({type: SHOP_ACTIONS.CLOSE_CART});
    };

    const handleOpen = (e) => {
        if (disabled) return;
        shopDispatch({type: SHOP_ACTIONS.OPEN_CART});
    };

    const handleProductOptionsClose = (e) => {
        shopDispatch({type: SHOP_ACTIONS.CLOSE_PRODUCT_OPTIONS});
    }

    const onAddProductHandler = () => {
        let hasEmptyOption = false;

        const availableOptions = {}
        
        if (shopState?.openProductOptions?.product?.options?.list) {
            Object.keys(shopState.openProductOptions.product.options.list)
                .map(option => {
                    if (Object.keys(shopState.openProductOptions.product.options.list[option]).filter(i => {
                        return shopState.openProductOptions.product.options.list[option][i].available
                    })?.length > 0) {
                        availableOptions[option] = shopState.openProductOptions.product.options.list[option]
                    }
                })
        }

        if (!options) {
            hasEmptyOption = true;
        }
        else {
            Object.keys(availableOptions).map(option => {
                if (!options[option]) hasEmptyOption = true;
            })
        }

        if (shopState?.openProductOptions?.product?.options?.list && hasEmptyOption) {
            showError({
                snackbarMessage: "Заповніть, будь ласка, деталі вашого замовлення"
            })
            return;
        }

        addProduct(
            shopState?.openProductOptions?.product,
            shopState?.openProductOptions?.shop,
            shopState?.openProductOptions?.selectedPrice,
            options,
            addons,
            flexible
        );

        // shopDispatch({type: SHOP_ACTIONS.OPEN_CART});
        shopDispatch({type: SHOP_ACTIONS.CLOSE_PRODUCT_OPTIONS});

        setOptions(null);
        setAddons(null);
    }

    const onUpdateProductOptionsHandler = () => {
        let hasEmptyOption = false;
        if (!isEmptyObj(options)) {
            Object.keys(shopState?.openEditProductOptions?.options).map(option => {
                if (!options?.[option]) hasEmptyOption = true;
            })
        }

        // ?????
        // let hasEmptyAddon = false;
        // Object.keys(shopState?.openEditProductOptions?.addons).map(addon => {
        //     if (!addons[addon]) hasEmptyAddon = true;
        // })

        if (hasEmptyOption) return;

        updateProduct(
            shopState?.openEditProductOptions?.details,
            shopState?.openEditProductOptions?.selectedPrice,
            shopState?.openEditProductOptions?.options,
            shopState?.openEditProductOptions?.addons,
            shopState?.openEditProductOptions?.flexible,
            null,
            options,
            addons,
            flexible
        );

        cleanSettings();
    }

    const cleanSettings = (e) => {
        shopDispatch({type: SHOP_ACTIONS.CLOSE_EDIT_PRODUCT_OPTIONS});
        setOptions(null);
        setAddons(null);
    }

    const onOptionsChange = (options) => {
        setOptions(options)
    }

    const onAddonsChange = (addons) => {
        setAddons(addons)
    }

    const onFlexibleChange = (data) => {
        // if (isEmptyObj(data.errors)) return;
        setFlexible(data.content)
    }

    return <>
        <AdaptiveDialog open={Boolean(shopState?.openCard)} onClose={handleClose} title={<><ShoppingCartIcon className='icon' /> Корзина</>}>
            <Cart
                onClose={handleClose}
                isLoading={isLoading}
                configs={configs}
                shops={shops}
            />
        </AdaptiveDialog>

        <AdaptiveDialog
            open={Boolean(shopState?.openEditProductOptions)}
            onClose={cleanSettings}
            title={<><ShoppingCartIcon className='icon' /> {isMobile ? 'Редагувати' : 'Редагувати замовлення'}</>}
        >
            {shopState?.openEditProductOptions && !shopState?.openEditProductOptions?.flexible && <ProductDetails
                onClose={handleClose}
                config={shopState?.openEditProductOptions?.details}
                product={shopState?.openEditProductOptions}
                options={options}
                addons={addons}
            />}

            {shopState?.openEditProductOptions?.selectedPrice && <br />}
            
            <Grid container spacing={2}>
                <EditOptions
                    values={shopState?.openEditProductOptions?.options}
                    product={shopState?.openEditProductOptions?.details}
                    onChange={onOptionsChange}
                />

                <EditAddons
                    values={shopState?.openEditProductOptions?.addons}
                    addons={shopState?.openEditProductOptions?.details?.addons}
                    currency={shopState?.openEditProductOptions?.details?.price?.currency}
                    onChange={onAddonsChange}
                />
                
                <EditFlexible
                    values={shopState?.openEditProductOptions?.flexible}
                    settings={shopState?.openEditProductOptions?.details?.price?.flexible}
                    onChange={onFlexibleChange}
                />

                <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant="outlined"
                        className='button'
                        onClick={cleanSettings}>
                            Відміна
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant="contained"
                        className='button'
                        onClick={onUpdateProductOptionsHandler}>
                            Оновити
                    </Button>
                </Grid>
            </Grid>
        </AdaptiveDialog>

        <AdaptiveDialog open={Boolean(shopState?.openProductOptions)} onClose={handleProductOptionsClose} title={<><ShoppingCartIcon className='icon' /> Деталі замовлення</>}>
            {shopState?.openProductOptions && !shopState?.openProductOptions?.product?.price?.flexible && <ProductDetails
                onClose={handleProductOptionsClose}
                config={shopState?.openProductOptions?.product}
                product={shopState?.openProductOptions}
                options={options}
                addons={addons}
            />}

            <Grid container spacing={2}>
                <EditOptions
                    product={shopState?.openProductOptions?.product}
                    onChange={onOptionsChange}
                />

                <EditAddons
                    addons={shopState?.openProductOptions?.product?.addons}
                    currency={shopState?.openProductOptions?.product?.price?.currency}
                    onChange={onAddonsChange}
                />

                <EditFlexible
                    settings={shopState?.openProductOptions?.product?.price?.flexible}
                    onChange={onFlexibleChange}
                />

                <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant="outlined"
                        className='button'
                        onClick={() => {
                            shopDispatch({type: SHOP_ACTIONS.CLOSE_PRODUCT_OPTIONS});
                        }}>
                            Відміна
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        variant="contained"
                        className='button'
                        onClick={onAddProductHandler}>
                            Додати
                    </Button>
                </Grid>
            </Grid>
        </AdaptiveDialog>

        {cart?.length > 0 && <Slide direction="left" in={!showActions}>
            <Badge className='shoppingCartIconBadge' badgeContent={cart.length} color="error">
                <Fab
                    className='shoppingCartIcon'
                    color="contrast"
                    onClick={handleOpen}>
                        <AddShoppingCartIcon />
                </Fab>
            </Badge>
        </Slide>}
    </>
}

function ProductDetails(props) {
    const {onClose, product, config, options, addons} = props;

    const navigate = useNavigate();

    if (!product || !config) return <></>

    const navigateToProductPage = (uri) => {
        navigate(restorePath(uri));
        onClose();
    }

    return <div className='ProductDetails'>
        <ContentImage
            onClick={() => navigateToProductPage(config._uri)}
            src="thumbnail"
            city={config.city}
            image={config.thumbnail}
            className='picture'/>

        <div className='title'>
            <span className='wrap' onClick={() => navigateToProductPage(config._uri)}>
                {config.title}
            </span>
        </div>
        {product.selectedPrice && <div className='subtitle'>
            {config?.price?.placeholder && <>{config?.price?.placeholder}: </>}
            <> {config?.price?.prices?.find(i => i.id === product.selectedPrice)?.title}</>
        </div>}

        {!config?.price?.flexible &&
            <div className='price'>
                <span className='labelWrap labelWrapRow'>
                    <span className='label'>Ціна:</span>
                    <span className='labelValue'>
                        <ShopProductPrice
                            product={config}
                            selectedPrice={product.selectedPrice}
                            addons={addons || product.addons}
                            options={options || product.options}
                        />
                        {/* {config.price.prefix && <span className='pricePrefix'>/ {config.price.prefix}</span>} */}
                    </span>
                </span>
            </div>
        }
    </div>
}

function Cart(props) {
    const {onClose, isLoading, configs, shops} = props;

    const {cart, addProduct, removeProduct, removeShop, clearCard} = useShoppingStore()

    const [openOrderForm, setOpenOrderFrom] = React.useState(null);
    const [openOrderShop, setOpenOrderShop] = React.useState();

    const [openedProductDetails, setOpenedProductDetails] = React.useState();

    const {userState, shopDispatch} = useGlobalCustomContext();

    const navigate = useNavigate();
    const moreThenOneShop = shops.length > 1

    function onEditHandler() {
        setOpenOrderFrom(null)
    }

    function handleClear() {
        clearCard();
        shopDispatch({type: SHOP_ACTIONS.CLOSE_CART});
    }

    const handleOrder = (storeId) => {
        setOpenOrderFrom(storeId)
    }

    const handleAdd = (productId, storeId, selectedPrice, options, addons, flexible) => {
        const item = cart.find(i =>
            i.product.id === productId
            && i.shop.id === storeId
            && i.selectedPrice === selectedPrice
            && isEqual(i.options, options)
            && isEqual(i.addons, addons)
            && isEqual(i.flexible, flexible)
        )

        if (item) addProduct(
            item.product,
            item.shop,
            selectedPrice,
            options,
            addons,
            flexible
        );
    }

    const handleRemove = (productId, storeId, selectedPrice, options, addons, flexible) => {
        const item = cart.find(i =>
            i.product.id === productId
            && i.shop.id === storeId
            && i.selectedPrice === selectedPrice
            && isEqual(i.options, options)
            && isEqual(i.addons, addons)
            && isEqual(i.flexible, flexible)
        )

        if (item) {
            if (cart.length === 1) shopDispatch({type: SHOP_ACTIONS.CLOSE_CART});
            removeProduct(item.product, selectedPrice, options, addons, flexible)
        }
        else {
            if (cart.length === 0) shopDispatch({type: SHOP_ACTIONS.CLOSE_CART});
        }
    }

    const shopTitleClickHendler = (storeId) => {
        setOpenOrderFrom(null)

        if (storeId === openOrderShop) {
            setOpenOrderShop(null)
        } else {
            setOpenOrderShop(storeId)
            setOpenOrderFrom(null)
        }
    }

    const navigateToShopPage = (shop) => {
        // const shop = {...shops.find(i => i.details.id === storeId)};
        // custom nodes (like tours), not shop-product, can have custom link to navigate - did
        if (shop.link) {
            const link = `/?goToDid=${shop.city}:${shop.link}`;
            navigate(link);
            onClose();
        }
    }

    const afterOrder = () => new Promise(async resolve => {
        removeShop(openOrderForm, (newCart) => {
            if (newCart.length === 0) {
                onClose();
            }
        });
        setOpenOrderFrom(null);
        resolve();
    })

    function getTotalPrice(storeId) {
        const shop = {...shops.find(i => i.details.id === storeId)}
        return totalPrice(shop?.items);
    }

    function getItemsForShop(storeId) {
        const shop = {...shops.find(i => i.details.id === storeId)}
        return shop.items
    }

    function getItemsForOrderField(storeId) {
        const data = {order: getItemsForShop(storeId)};
        return data
    }

    function getFormForShopConfig(storeId) {
        const shop = shops.find(i => i.details.id === storeId)
        let result = {
            form:  {
                endpoint: `/form/${shop.details.city}/shop/${shop.details.partner}/${shop.details.id}`,
                snackbarDuration: 10000
            }
        }

        // add partner and shop
        // result.form.endpoint += shop.details._uri;
        result.form.submitText = shop.details?.orderForm?.form?.submitText || configs.shop.form.submitText;
        result.form.successMessage = shop.details?.orderForm?.form?.successMessage || configs.shop.form.successMessage;

        result.fields = shop.details?.orderForm?.fields || configs.shop.fields
        result.fieldsOrder = shop.details?.orderForm?.fieldsOrder || configs.shop.fieldsOrder

        // add order hidden field
        // TODO: use same code used on server side
        result.fields.order = {
            options: {
                hidden: true,
            },
            placeholder: 'Замовлення',
            required: true,
            type: 'json',
        }

        const defaultValues = {}
        Object.keys(result.fields).map(field => {
          if (result.fields[field].default) {
            const defVal = attachUserData(result.fields[field].default, userState?.user)
    
            if (defVal) {
              defaultValues[field] = defVal
            }
          }
        })
        result.defaultValues = defaultValues;

        if (shop?.details?.orderForm?.defaultValues) {
            result.defaultValues = {...result.defaultValues, ...shop.details.orderForm.defaultValues}
        }
        else if (configs.shop.defaultValues) {
            result.defaultValues = {...result.defaultValues, ...configs.shop.defaultValues}
        }

        return result
    }

    const isShopNotCollapsed = (storeId) => {
        if (openOrderForm) {
            return false
        }

        if (!moreThenOneShop) {
            return true
        }

        return openOrderShop === storeId && openOrderForm !== storeId;
    }

    const onEditProductOption = (product) => {
        shopDispatch({type: SHOP_ACTIONS.OPEN_EDIT_PRODUCT_OPTIONS, data: product});
    }

    const isEqualProduct = (a, b) => {
        // i.details.id === item.product.id
        // && 
        return a?.selectedPrice === b?.selectedPrice
            && isEqual(a?.options, b?.options)
            && isEqual(a?.addons, b?.addons)
            && isEqual(a?.flexible, b?.flexible)
        // return isEqual(a, b)
    }

    const displayDetails = (product) => {
        if (isEqualProduct(openedProductDetails, product)) {
            setOpenedProductDetails(null)    
        }
        else {
            setOpenedProductDetails(product)
        }
    }

    return <div className="Cart">
        {isLoading && <div className='CartPreloaderWrap'><Preloader /></div>}

        {!isLoading && shops.map((store) =>
            <div className='shopItem' key={'cart_shop_item_' + store.details.id}>
                <div className={isShopNotCollapsed(store.details.id) ? 'shopTitle active' : 'shopTitle'}>
                    <div className='titleWrap'>
                        <span className='title'>
                            <PartnerImage
                                id={store.details.partner}
                                shop={store.details.id}
                                className={`Logo Logo32 ${store.details.link ? 'Clickable' : ''}`}
                                onClick={() => navigateToShopPage(store.details)}
                            />
                            <span className='name' onClick={() => shopTitleClickHendler(store.details.id)}>
                                {store.details.title}
                                &nbsp;
                                <span className='q'>({store?.items?.length} товар{store?.items?.length > 1 && store?.items?.length < 6 && 'и'}{store?.items?.length > 5 && 'ів'})</span>
                            </span>
                        </span>
                        {moreThenOneShop && <span onClick={() => shopTitleClickHendler(store.details.id)}>
                            <KeyboardArrowUpIcon className='icon up' />
                            <KeyboardArrowDownIcon className='icon down' />
                        </span>}
                    </div>
                </div>

                <Collapse in={isShopNotCollapsed(store.details.id)}>
                    <div className='productsList'>
                        {store?.items?.sort(orderProducts).map(product =>
                        <div className='product' key={'cart_shop_product_item_' + store.details.id + '_' + product.details.id + '_' + product.selectedPrice + '_' + (product.options ? JSON.stringify(product.options) : 'no') + '_' + (product.flexible ? JSON.stringify(product.flexible) : 'no')}>
                            {(!isEmptyObj(product.options) || product?.addons?.list?.length > 0 || !isEmptyObj(product.flexible)) && <span className='editProductOptions' onClick={() => onEditProductOption(product)}><EditOutlinedIcon /></span>}

                            <ProductDetails
                                onClose={onClose}
                                config={product?.details}
                                product={product}
                            />

                            {/* {product?.details?.price?.flexible && <div className='actions' onClick={() => handleRemove(
                                    product.details.id,
                                    store.details.id,
                                    product.selectedPrice,
                                    product.options,
                                    product.addons,
                                    product.flexible
                                )}>
                                    <RemoveCircleOutlineIcon className='btn'/>
                                    <span>Видалити</span>
                            </div>} */}

                            {/* {!product?.details?.price?.flexible &&  */}
                            <div className='quantity'>
                                <div className='wrap'>
                                    <RemoveCircleOutlineIcon className='btn' onClick={() => handleRemove(
                                        product.details.id,
                                        store.details.id,
                                        product.selectedPrice,
                                        product.options,
                                        product.addons,
                                        product.flexible
                                    )
                                    }/>
                                    {product.amount}
                                    <AddCircleOutlineIcon className='btn' onClick={() => handleAdd(
                                        product.details.id,
                                        store.details.id,
                                        product.selectedPrice,
                                        product.options,
                                        product.addons,
                                        product.flexible
                                    )}/>
                                </div>
                            </div>
                            {/* } */}

                            {(!isEmptyObj(product.flexible) || !isEmptyObj(product.options) || product?.addons?.list?.length > 0) && <div className={isEqualProduct(openedProductDetails, product) ? `details opened` : 'details'} onClick={() => displayDetails(product)}>
                                <span className='pseudoLink'>Деталі замовлення</span> 
                                <KeyboardArrowUpIcon className='icon up' />
                                <KeyboardArrowDownIcon className='icon down' />
                            </div>}

                            <Collapse in={isEqualProduct(openedProductDetails, product)}>
                                {product.flexible && !isEmptyObj(product.flexible) && <div className='flexible'>
                                    {Object.keys(product.flexible).map(id => <span className='labelWrap labelWrapRow' key={'cart_shop_product_item_flexible' + store.details.id + '_' + product.details.id + '_' + id}>
                                        <span className='label'>{product.details?.price?.flexible?.[id]}:</span>
                                        <span className='labelValue'>
                                            {product.flexible[id]}
                                        </span>
                                    </span>)}
                                </div>}

                                {product.options && !isEmptyObj(product.options) && <div className='options'>
                                    {Object.keys(product.options).map(id => <span className='labelWrap' key={'cart_shop_product_item_option' + store.details.id + '_' + product.details.id + '_' + id}>
                                        <span className='label'>{product.details.options?.list?.[id]?.title}:</span>
                                        <span className='labelValue'>
                                            <Chip variant="outlined" label={
                                                product.details.options?.list?.[id]?.list?.[product.options[id]]?.title
                                            } />
                                        </span>
                                    </span>)}
                                </div>}

                                {product?.addons?.list?.length > 0 && <div className='addons'>
                                    <span className='labelWrap'>
                                        <span className='label'>
                                            {product?.details?.addons?.placeholder}
                                            :
                                        </span>
                                        <span className='labelValue'>
                                        {product.addons.map(addon =>
                                            <Chip variant="outlined"
                                                key={'cart_shop_product_item_addon' + store.details.id + '_' + product.details.id + '_' + addon.id}
                                                label={
                                                    addon.title
                                                    + (addon?.quantity > 1 ? `(${addon.quantity})` : '')
                                            } />
                                        )}
                                        </span>
                                    </span>
                                </div>}
                            </Collapse>

                        </div>
                        )}
                    </div>

                    {getTotalPrice(store.details.id) && <div className='finalPrice'>
                        <span className='price'>
                            До сплати:
                            <br/>
                            <span className="priceValue">
                                {getTotalPrice(store.details.id)}
                            </span>
                        </span>

                        <Button
                            variant="contained"
                            className='button'
                            fullWidth={!getTotalPrice(store.details.id)}
                            disabled={store.details.id === openOrderForm}
                            onClick={() => handleOrder(store.details.id)}>
                                Оформити
                        </Button>
                    </div>}

                    {!getTotalPrice(store.details.id) && <Button
                        variant="contained"
                        className='button'
                        fullWidth={!getTotalPrice(store.details.id)}
                        disabled={store.details.id === openOrderForm}
                        onClick={() => handleOrder(store.details.id)}>
                            Оформити
                    </Button>}
                </Collapse>

                <Collapse in={openOrderForm === store.details.id}>
                    {openOrderForm && <div className='FormContainerWrap'>
                        <ShortOrderDescription orders={getItemsForShop(store.details.id)} />
                        <FormContainer
                            content={getItemsForOrderField(store.details.id)}
                            config={getFormForShopConfig(store.details.id)}
                            afterSubmit={afterOrder}
                        />
                        <div className='editOrder'>
                            <Button fullWidth
                                variant="outlined"
                                onClick={onEditHandler}>
                                    Змінити замовлення
                            </Button>
                        </div>
                    </div>}
                </Collapse>
            </div>
        )}
        <div className='Submit'>
            <Button onClick={handleClear} variant="outlined" fullWidth>Очистити корзину</Button>
        </div>
    </div>
}
