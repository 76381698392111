import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {useNavigate} from "react-router-dom";
import {isUndefined} from 'lodash';

import { isEmptyObj } from "ultra/helpers/utils";
import {restorePath} from 'ultra/helpers/route'
import {getPartnerDetails} from 'ultra/helpers/shop';
import {getClientCity} from 'ultra/configs/general'
import {DEFAULT_CITY} from 'ultra/const/general';
import {hasFullAccess} from 'ultra/helpers/auth';

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

import Button from '@mui/material/Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import ShopingCart from '../ShopingCart'
import ContentImage from '../ContentImage';
import ShopCategoryLabel from '../ShopCategoryLabel';
// import ShopProductPrice from '../ShopProductPrice';
import Pagination from '../Pagination';

import PreloaderShop from '../../../../Components/PreloaderShop';

import Links from '../../Widgets/Links'
import FilterChip from '../../Widgets/FilterChip'
import NodeInlineActions from '../../Widgets/NodeInlineActions';

import {useSearchStore} from '../../../../Stores/search';
import {useConfigStore} from '../../../../Stores/config';

// import {filterContent} from '../../Helpers/content';

import {getFilter} from "../../../../Helpers/filters";
import {useGlobalCustomContext} from '../../../../Helpers/context';

import './index.scss'

function Products(props) {
    const {products, shop} = props;
    const {configs} = useConfigStore();
    const {userState} = useGlobalCustomContext();
    const [city] = useState(getClientCity(window) || DEFAULT_CITY);

    const navigate = useNavigate();

    return Object.keys(products).map(product => {
            const item = products[product].data
            return <Grid item xs={6} md={3} key={item._uri}>
                <div className={`productWrap ${item.available ? '' : 'productNotAvailable'}`}>
                    <RouterLink to={restorePath(item._uri)}>
                        <div className='photoWrap'>
                            <ContentImage src="thumbnail" image={item.thumbnail} className="photo" />
                            
                            {!item.available && <div className="photoLabel">
                                <Chip label="Тимчасово недоступно" />
                            </div>}

                            {item.available && item.status && <div className="photoLabel">
                                <FilterChip value={item.status} config={configs.content.nodes['shop-product'].fields.status} />
                            </div>}
                        </div>
                    </RouterLink>
                    
                    <NodeInlineActions direction="up" permits={products[product].permits} node={item} city={city} hasFullAccess={hasFullAccess(userState.user)} />

                    <div className="productTitle">{item.title}</div>

                    <div className="button">
                        {!item?.price?.prices && <ShopingCart
                            fullWidth
                            item={item}
                            shop={shop}
                            showPrice
                        />}

                        {item?.price?.prices && <Button
                                fullWidth
                                variant="contained"
                                onClick={() => navigate(restorePath(item._uri))}
                            >
                                <InfoOutlinedIcon />
                                &nbsp;
                                Детальніше
                        </Button>}
                    </div>

                    {/* <div className="price">
                        <ShopProductPrice product={item} />
                    </div> */}
                </div>
            </Grid>
        })
}

function SortedProducts(props) {
    const {items, shop, showPagination, noCategorisedLinks} = props;

    const [sortedProducts, setSortedProducts] = useState([])

    useEffect(() => {
        const sorted = []
        items.order.map(link => {
            // let list = getFilteredList();
            const {list, permits} = items;
    
            if (list[link]?._type !== 'shop-product' && list[link]?._type !== 'shop-alias') return;
            sorted.push({
                permits: permits[link],
                data: {...list[link]}
            });
        })

        setSortedProducts(sorted)
    }, [])

    if (sortedProducts.length === 0) return <></>

    return <div className="ShopProductsList">
        <Grid container spacing={4}>
            <Products shop={shop} products={sortedProducts} />

            {showPagination && <Grid item xs={12}>
                <Pagination pagination={items.pagination} />
            </Grid>}

            <Links links={noCategorisedLinks}/>
        </Grid>
    </div>
}

export default function ShopProductsList(props) {
    const {items, breadcrumbs, content, showPagination, showSkeleton, skeletonHideBreadcrumbs} = props // searchBy
    // permits

    const {configs} = useConfigStore();
    const {search} = useSearchStore();

    const [city] = useState(getClientCity(window) || DEFAULT_CITY);

    const [shop, setShop] = useState()
    const [categoriesSettings, setCategoriesSettings] = useState()
    
    const [noCategorisedLinks, setNoCategorisedLinks] = useState()
    const [noCategorisedProducts, setNoCategorisedProducts] = useState()
    const [categories, setCategories] = useState()
    const [byCategories, setByCategories] = useState()

    // function getFilteredList() {
    //     let list = items?.list;
    //     if (items?.list && search && searchBy) {
    //         list = filterContent(configs.content.nodes, items?.list, searchBy, search)
    //     }
    //     return list;
    // }

    useEffect(() => {
        const shopData = getPartnerDetails(content, breadcrumbs)?.node;

        if (items) {
            // let list = getFilteredList();
            const {list, permits, order} = items;

            // shop has categories config
            if (shopData?.categories) {
                let config

                if (shopData?.categories?.source) {
                    config = getFilter(configs.filters, city, shopData.categories.source);
                } else {
                    config = shopData?.categories?.values
                }

                const noCategoriesItems = {}
                const noCategoriesProducts = {}
                const sortedProducts = {}
                const categoriesOrder = config.order || Object.keys(config.values).sort()

                categoriesOrder?.map(category => {
                    order.map(item => {
                        const product = list[item];
                        if (product?.category === category) {
                            sortedProducts[category] = sortedProducts[category] || []
                            sortedProducts[category].push({
                                permits: permits[item],
                                data: product
                            })
                        }
                    })
                });

                order.map(item => {
                    if (!categoriesOrder?.includes(list[item]?.category)) {
                        if (list[item]._type === 'shop-product') {
                            noCategoriesProducts[item] = {
                                permits: permits[item],
                                data: list[item]
                            }
                        }
                        else {
                            noCategoriesItems[item] = list[item]
                        }
                    }
                });

                setCategoriesSettings(config);
                setByCategories(sortedProducts)
                setCategories(categoriesOrder);
                setNoCategorisedLinks({
                    list: noCategoriesItems,
                    order: Object.keys(noCategoriesItems).sort()
                });

                setNoCategorisedProducts(noCategoriesProducts);
            }
            // no categories shop config
            else {
                const categories = []

                if (items) {
                    Object.keys(list).find(i => {
                        const {category} = list[i];

                        if (!isUndefined(category) && !categories.includes(category)) {
                            categories.push(category);
                        }
                    });
                }

                setCategories(categories);
            }
            setShop(shopData);
        }
    }, [items, search])

    if (showSkeleton || !shop) return <PreloaderShop hideBreadcrumbs={skeletonHideBreadcrumbs} />

    if (shop?.categories) {
        if (!categories || categories.length === 0) return <></>

        return <div className="ShopProductsList">
            {categories.map(category => <React.Fragment key={category}>
                {byCategories?.[category] &&
                    <ShopCategoryLabel category={category} categories={categoriesSettings} />
                }

                {byCategories?.[category] && <Grid container spacing={4}>
                    <Products shop={shop} products={byCategories[category]} />
                </Grid>}
            </React.Fragment>)}

            {!isEmptyObj(noCategorisedProducts) && <>
                <div className="ShopCategoryLabel">
                    Інше
                </div>
                <Grid container spacing={4}>
                    <Products shop={shop} products={noCategorisedProducts}/>
                </Grid>
            </>}
        </div>
    }

    // NO CATEGORIES
    // REFACTORING !!!

    return <SortedProducts items={items} shop={shop} showPagination={showPagination} noCategorisedLinks={noCategorisedLinks} />
}
