import {useEffect, useState} from 'react';

import {useSearchParams, useLocation} from "react-router-dom";

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {getClientCity} from 'ultra/configs/general';
import {DEFAULT_CITY} from 'ultra/const/general';
import {normalizeRoute} from "ultra/helpers/route"; // restorePath

import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useVirtualUriStore} from '../../../../../../../../Stores/url';

// import PreloaderTopPagePicture from '../../../../../../../../Components/PreloaderTopPagePicture';
import PreloaderText from '../../../../../../../../Components/PreloaderText';

import ShopProductsList from '../../../../../../Widgets/ShopProductsList'
import ContentImage from '../../../../../../Widgets/ContentImage'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import LinksLines from '../../../../../../Widgets/LinksLines'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import TitleWidget from '../../../../../../Widgets/Title'
import TopPagePicture from '../../../../../../Widgets/TopPagePicture';

import {getNodeContent} from '../../../../../../../../Helpers/content';
import {useGlobalCustomContext} from '../../../../../../../../Helpers/context';

import './index.scss'

export default function Template(props) {
    const {content, config, children, permits, breadcrumbs} = props;

    const {removeVirtualUri, setVirtualUri} = useVirtualUriStore();

    let [searchParams, setSearchParams] = useSearchParams();

    const {nodeState, nodeDispatch} = useGlobalCustomContext();
    const {contentLoading} = useNavigationStore()
    const location = useLocation();

    const [hasProducts, setHasProducts] = useState();
    const [categories, setCategories] = useState();

    const [dynamicChildren, setDynamicChildren] = useState();
    const [dynamicPreloader, setDynamicPreloader] = useState();

    const city = getClientCity(window) || DEFAULT_CITY;

    // useEffect(() => {
    //     setVirtualUri(getVirtualUri());
    // }, [searchParams])

    useEffect(() => {
        virtualLinkHandler(getVirtualUri());
    }, [])

    useEffect(() => {
        let hasProducts = false;
        const list = {};
        const permits = {};
        const order = [];

        children.order.map(item => {
            if (children.list[item]._type === 'shop-product' || children.list[item]._type === 'shop-alias') {
                hasProducts = true;
            }
    
            if (children.list[item]._type === 'shop-section') {
                list[item] = children.list[item];
                permits[item] = children.permits[item];
                order.push(item);
            }
        })

        if (content?.virtualCatalogs) {
            list[content._uri] = {...content}
            list[content._uri].title = <span className='mainSection'>
                {/* <HomeOutlinedIcon /> */}
                {/* <span>{content?.virtualCatalogs?.root}</span> */}
                {content?.virtualCatalogs?.root}
            </span>
            order.unshift(content._uri)
        }

        setHasProducts(hasProducts)
        setCategories({
            list,
            order, // TODO: add default sort
            permits,
            // showFilters: content.showFilters,
        })
    }, [children])

    useEffect(() => {
        const url = new URL(window.location.origin + location.pathname + location.search);
        const virtualUri = url.searchParams.get('virtual_uri');
        if (nodeState.updatePageContentStart) {
            updateData(virtualUri || content._uri, false);
        }
    }, [nodeState.updatePageContentStart])

    const getVirtualUri = () => {
        const url = new URL(window.location.origin + location.pathname + location.search);
        return url.searchParams.get('virtual_uri');
    }

    const updateData = (uri, ignoreCache = true) => {
        setDynamicPreloader(true)
        getNodeContent({city, uri}, null, ignoreCache)
            .promise
            .then(data => {
                setDynamicChildren(data.children)
                setDynamicPreloader(false)
            })
    }

    const virtualLinkHandler = (uri) => {
        if (!uri) return;

        setVirtualUri(uri);

        if (normalizeRoute(uri) === normalizeRoute(content._uri)) {
            setSearchParams({});
            // removeVirtualUri()
        }
        else {
            setSearchParams({virtual_uri: uri});
        }

        updateData(uri, false);
    }

    if (contentLoading) return <div className='Shop'>
        {/* <PreloaderTopPagePicture /> */}
        <ShopProductsList showSkeleton={true} />
        <PreloaderText />
    </div>

    return <div className="Shop">
        {content.banner && (!content.showPagination || (content.showPagination && children?.pagination?.current === 1))
            && <TopPagePicture cutHeightForDesktop showSkeleton={contentLoading} image={content.banner}/>}

        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {/* TODO: add design */}
        {/* {sections && <Grid className="sectionsList" container spacing={4}>
            {sections.map(section =>
                <Grid item xs={6} md={3} key={section._uri}>
                    <div>
                        <RouterLink to={restorePath(section._uri)}>
                            {section.title}
                        </RouterLink>
                    </div>
                </Grid>
            )}
        </Grid>} */}

        {/* CATEGORIES */}
        {/* not filter categories */}
        {content?.virtualCatalogs && categories?.order?.length > 0 && <LinksLines
            config={config}
            links={categories}
            className="VirtualCatalogs"
            linkHandler={virtualLinkHandler}
            xs={5}
            md={2}
        />}

        {!content?.virtualCatalogs && categories?.order?.length > 0 && <LinksLines
            config={config}
            links={categories}
        />}

        {/* PRODUCTS */}
        {hasProducts && <ShopProductsList
            currency={content?.partner?.config?.currency}
            breadcrumbs={breadcrumbs}
            config={config}
            content={content}

            skeletonHideBreadcrumbs={true}
            showSkeleton={dynamicPreloader}
            items={dynamicChildren || children}
        />}

        {content.description && <div className='ShopDescription'>
            <ContentImage src="thumbnail" image={content.thumbnail} className="thumbnail" />
            <CKEditorContent content={content.description} />
        </div>}
    </div>
}
