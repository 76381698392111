import React, {useEffect, useState} from 'react';
import {sortBy} from 'lodash';
import {isMobile} from "react-device-detect";

import Switch from '@mui/material/Switch';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import FormControlLabel from '@mui/material/FormControlLabel';

import {transliterate} from 'ultra/helpers/utils';

import CurrencyFormated from '../../../Components/CurrencyFormated'
import Text from '../Text';
import Number from '../Number';

import './index.scss';

export default function ProductAddonsField(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    const [addPriceForm, setAddPriceForm] = useState(false);

    const [variantTitle, setVariantTitle] = useState();
    const [variantPrice, setVariantPrice] = useState();
    const [variantCounter, setVariantCounter] = useState(true);

    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    useEffect(() => {
        setClassName('ProductAddonsField inputEmulation'
            + (isDisabled ? ' disabled' : '')
            + (showErrorText ? ' error' : ''))
    }, [showErrorText])

    const [className, setClassName] = useState('ProductAddonsField inputEmulation'
        + (isDisabled ? ' disabled' : '')
        + (showErrorText ? ' error' : ''));

    const onPlaceholderChange = (e) => {
        const value = content[field.id] || {};
        value.placeholder = e.target.value;

        onChange({target: {value}})
    }

    const onAddMultiPriceHandle = () => {
        if (!variantTitle || !variantPrice) return;

        const value = content[field.id] || {};

        if (!value?.list) value.list = [];

        const id = transliterate(variantTitle);

        // check is id present
        if (value.list.find(i => i.id === id)) {
            alert('Варіант вже доступний')
            return;
        }

        value.list.push({
            title: variantTitle,
            price: variantPrice,
            counter: variantCounter,
            id
        })

        value.list = sortBy(value.list, (o) => o.title)

        onChange({target: {value}})
        setVariantTitle()
        setVariantPrice()
        setVariantCounter(true)
    }

    const onRemoveMultiPriceHandle = (id) => {
        const value = content[field.id] || {};

        const i = value?.list?.findIndex(i => i.id === id);
        if (i > -1) { value?.list.splice(i, 1); }

        onChange({target: {value}});
    }

    const onVariantTitleChange = (e) => {
        setVariantTitle(e.target.value);
    }

    const onVariantPriceChange = (e) => {
        setVariantPrice(e.target.value);
    }

    const onVariantCounterChange = () => {
        setVariantCounter(!variantCounter);
    }

    return <div className={className}>
        <div className='inputLabel'>
            {field.placeholder} {field.required ? '*' : ''}
        </div>

        <Grid container spacing={2} className='multiPriceBlock'>
            <Grid item xs={12}>
                <Grid container>
                    <Text
                        disabled={isDisabled}
                        field={{id: 'placeholder', placeholder: "Підпис"}}
                        content={content[field.id] || {}}
                        onChange={onPlaceholderChange}
                    />
                </Grid>
            </Grid>
        </Grid>

        <div className='addonsTable'>
            <div className='addonsTableTitle'>
                <span className='label'>Значення:</span>
            </div>
            {content[field.id]?.list?.length > 0 && content[field.id]?.list?.map(i => <Grid key={`addons_item_${i.id}`} container spacing={1}>
                <Grid item xs={12} md={8}>
                    <div>
                        {i.title}
                        {i.counter && <div className='counter'>Можливість замовити більше однієї одиниці</div>}
                    </div>
                </Grid>
                <Grid item xs={12} md={3}>
                    <CurrencyFormated value={i.price} currency={content.price.currency} />
                </Grid>
                <Grid item xs={12} md={1}>
                    {!isMobile && <ClearIcon className='removeOption' onClick={() => onRemoveMultiPriceHandle(i.id)}/>}

                    {isMobile && <Button
                        fullWidth
                        variant="outlined"
                        component="label"
                        tabIndex={-1}
                        size='small'
                        onClick={() => onRemoveMultiPriceHandle(i.id)}
                    >
                        <ClearIcon />
                        <span>Видалити</span>
                    </Button>}
                </Grid>
            </Grid>)}

            <div className="inputEmulation">
                <span className="inputLabel">Додати</span>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Grid container>
                            <Text
                                disabled={isDisabled}
                                field={{id: 'title', placeholder: "Варіант"}}
                                content={{title: variantTitle}}
                                onChange={onVariantTitleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Grid container>
                            <Number
                                disabled={isDisabled}
                                field={{id: 'price', placeholder: "Ціна"}}
                                content={{price: variantPrice}}
                                onChange={onVariantPriceChange}
                            />
                        </Grid>
                    </Grid>

                    {isMobile && <Grid item xs={12}>
                        <FormControlLabel control={<Switch checked={variantCounter} onChange={onVariantCounterChange} />} label={"Можливість замовити більше однієї одиниці"} />
                    </Grid>}

                    <Grid item xs={12} md={2}>
                        <Button
                            fullWidth
                            variant="outlined"
                            component="label"
                            tabIndex={-1}
                            onClick={onAddMultiPriceHandle} // id
                        >
                            <AddIcon />
                            {isMobile && <span>Додати</span>}
                        </Button>
                    </Grid>

                    {!isMobile && <Grid item xs={12}>
                        <FormControlLabel control={<Switch checked={variantCounter} onChange={onVariantCounterChange} />} label={"Можливість замовити більше однієї одиниці"} />
                    </Grid>}

                </Grid>
            </div>
        </div>

        {field?.notes && <div className='fieldNotes'>
            <p>{field?.notes}</p>
        </div>}
        {showErrorText && <div className='fieldError'>
            {showErrorText}
        </div>}
    </div>
}
