import {isArray} from "lodash";

import {useNavigationStore} from '../../../../../../../../Stores/navigation';
import {useConfigStore} from '../../../../../../../../Stores/config';

import RecomendationDescriptionPreloader from '../../../../../../../../Components/RecomendationDescriptionPreloader'

import TitleWidget from '../../../../../../Widgets/Title'
import BreadcrumbsWidget from '../../../../../../Widgets/Breadcrumbs'
import LocationWidget from '../../../../../../Widgets/LocationWidget'
import MessengerField from '../../../../../../Widgets/MessengerField'
import URLField from '../../../../../../Widgets/URLField'
import ContentImage from '../../../../../../Widgets/ContentImage'
import CKEditorContent from '../../../../../../Widgets/CKEditorContent'
import URLValue from '../../../../../../Widgets/URLValue'
import FilterChip from '../../../../../../Widgets/FilterChip'

import './index.scss';

export default function Template(props) {
    const {content, permits, config, form, breadcrumbs} = props

    const {contentLoading} = useNavigationStore()
    const {configs} = useConfigStore();

    return <div className='Recomendation'>
        <TitleWidget permits={permits} content={content} config={config} breadcrumbs={breadcrumbs} hide={content.hideTitle} />
        <BreadcrumbsWidget links={breadcrumbs} hide={content.hideBreadcrumbs}/>

        {contentLoading && <RecomendationDescriptionPreloader />}

        {!contentLoading && content.thumbnail && <div className='RecommendationCard'>
            <ContentImage src="thumbnail" image={content.thumbnail} className="thumbnail" />
            {content['partner-product'] && <URLValue className="pseudoButton" value={content['partner-product']} />}
        </div>}

        {!contentLoading && <div className='RecomendationDescription'>
            {!content.description && <CKEditorContent content="<p>&nbsp;</p><p><i>Детальний опис відсутній</i></p>" />}
            {content.description && <CKEditorContent content={content.description} />}
            {content.messenger && <MessengerField field="messenger" type={content._type} content={content} />}
            {content.url && <URLField field="url" type={content._type} content={content} />}
        </div>}

        {!contentLoading && isArray(content.category) && content.category.length > 0
            && <div className='categories'>
                {content.category.map(i => <FilterChip key={`recommendation_category_${i}`} value={i} config={configs.content.nodes[content._type].fields.category || form.fields.category} />)}
            </div>
        }

        {content.location && <LocationWidget showSkeleton={contentLoading} location={content.location} />}
    </div>
}
