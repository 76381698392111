import React, {useEffect, useState} from 'react';
import {sortBy} from 'lodash';
import {isMobile} from "react-device-detect";

import Switch from '@mui/material/Switch';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import {transliterate} from 'ultra/helpers/utils';
import {getClientCity, getCityConfig} from 'ultra/configs/general';

import {useConfigStore} from '../../../Stores/config';

import CurrencyFormated from '../../CurrencyFormated'

import Text from '../Text';
import Number from '../Number';

import './index.scss';

export default function Price(props) {
    const {content, disabled, field, isEditMode, onChange, showErrorText} = props;

    const {configs} = useConfigStore();

    const [isDisabled, setIsDisabled] = useState(disabled || field?.options?.disabled || (isEditMode && field.notEditable))
    const [cityConfig] = useState(getCityConfig(configs, getClientCity(window)));
    const [multiPriceValue, setMultiPriceValue] = useState({});
    const [multiPrice, setMultiPrice] = useState(Boolean(content[field.id]?.prices));
    const [flexiblePrice, setFlexiblePrice] = useState(Boolean(content[field.id]?.flexible));
    const [newFlexibleValue, setNewFlexibleValue] = useState({});
    const [free, setFree] = useState(Boolean(content[field.id]?.free));

    useEffect(() => {setIsDisabled(disabled || field?.options?.disabled || (isEditMode && field.notEditable)) }, [disabled])

    useEffect(() => {
        setClassName('Price inputEmulation'
            + (isDisabled ? ' disabled' : '')
            + (showErrorText ? ' error' : ''))
    }, [showErrorText])

    const [className, setClassName] = useState('Price inputEmulation'
        + (isDisabled ? ' disabled' : '')
        + (showErrorText ? ' error' : ''));

    const onCurrencyChange = (e) => {
        const value = content[field.id] || {};
        value.currency = e.target.value;
        onChange({target: {value}})
    }

    const onValueChange = (e) => {
        const value = content[field.id] || {};
        value.price = e.target.value;
        onChange({target: {value}})
    }

    const onDiscountChange = (e) => {
        const value = content[field.id] || {};
        value.discount = e.target.value;
        onChange({target: {value}})
    }

    const onPrefixChange = (e) => {
        const value = content[field.id] || {};
        value.prefix = e.target.value;
        onChange({target: {value}})
    }

    const onMultiPricePlaceholderChange = (e) => {
        const value = content[field.id] || {};
        value.placeholder = e.target.value;

        onChange({target: {value}})
    }

    const onMultiPricePrefixChange = (e) => {
        const value = content[field.id] || {};
        value.prefix = e.target.value;

        onChange({target: {value}})
    }

    const onFreeChange = (e) => {
        onChange({target: {value: {free: !free}}})
        setFree(!free)
        setFlexiblePrice(false)
    }

    const onFlexibleChange = (e) => {
        setFlexiblePrice(!flexiblePrice)
        onChange({target: {value: {flexible: !flexiblePrice ? {} : undefined}}})
    }

    const onMultiChange = (e) => {
        if (!multiPrice) {
            const value = {
                prices: content[field.id]?.prices,
                prefix: content[field.id]?.prefix,
                placeholder: content[field.id]?.placeholder,
                currency: content[field.id]?.currency,
            }
            onChange({target: {value}})
        }
        else {
            const value = {
                price: content[field.id]?.price,
                discount: content[field.id]?.discount,
                currency: content[field.id]?.currency,
            }
            onChange({target: {value}})
        }

        setMultiPrice(!multiPrice);
    }

    const onMultiPriceTitleChange = (e) => {
        const value = {...multiPriceValue};
        value.title = e.target.value;

        setMultiPriceValue(value);
    }

    const onMultiPricePriceChange = (e) => {
        const value = {...multiPriceValue};
        value.price = e.target.value;

        setMultiPriceValue(value);
    }

    const onMultiPriceFreeChange = (e) => {
        const value = {...multiPriceValue};

        value.price = undefined;
        value.free = e.target.value;

        setMultiPriceValue(value);
    }

    const onAddMultiPriceHandle = () => {
        if (!multiPriceValue.title) return;
        if (!multiPriceValue.free && !multiPriceValue.price) return;

        const value = content[field.id] || {};

        if (!value?.prices) value.prices = [];

        const id = transliterate(multiPriceValue.title);

        // check is id present
        if (value.prices.find(i => i.id === id)) {
            alert('Опція вже доступна')
            return;
        }

        value.prices.push({
            ...multiPriceValue,
            id
        })

        value.prices = sortBy(value.prices, (o) => o.title)

        setMultiPriceValue({});
        onChange({target: {value}})
    }

    const onRemoveMultiPriceHandle = (id) => {
        const value = content[field.id] || {};

        const i = value?.prices?.findIndex(i => i.id === id);
        if (i > -1) { value?.prices.splice(i, 1); }

        onChange({target: {value}});
    }

    const onNewFlexibleChange = (e) => {
        setNewFlexibleValue({title: e.target.value});
    }

    const onAddFlexibleHandle = () => {
        if (!newFlexibleValue?.title) return;

        // console.log(newFlexibleValue?.title)

        const value = content[field.id] || {};
        value.flexible = value.flexible || {}

        // if (!value?.prices) value.prices = [];

        const id = transliterate(newFlexibleValue.title);

        // check is id present
        if (value.flexible[id]) {
            alert('Опція вже доступна')
            return;
        }

        value.flexible[id] = newFlexibleValue.title

        setNewFlexibleValue({})
        onChange({target: {value}})
    }

    const onRemoveFlexibleHandle = (id) => {
        const value = content[field.id] || {};
        delete value?.flexible[id]

        onChange({target: {value}});
    }

    return <div className={className}>
        <div className='inputLabel'>
            {field.placeholder} {field.required ? '*' : ''}
        </div>

        {(!free && !flexiblePrice) && <>
            {!multiPrice && <Grid container spacing={1} className='priceBlock'>
                <Grid item xs={12} md={6}>
                    <Number
                        disabled={isDisabled}
                        field={{...field, id: 'price', placeholder: "Ціна"}}
                        content={{price: content[field.id]?.price || undefined}}
                        onChange={onValueChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Number
                        disabled={isDisabled}
                        field={{...field, id: 'discount', placeholder: "Знижка"}}
                        content={{discount: content[field.id]?.discount || undefined}}
                        onChange={onDiscountChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl disabled={isDisabled} fullWidth>
                        <InputLabel>Валюта</InputLabel>
                        <Select
                            disabled={isDisabled}
                            label="Валюта"
                            value={content[field.id]?.currency || ''}
                            onChange={onCurrencyChange}>
                                {cityConfig?.currency && <MenuItem key={cityConfig?.currency} value={cityConfig?.currency}>
                                    {cityConfig?.currency.toUpperCase()}
                                </MenuItem>}
                                {cityConfig.additionalCurrencies && cityConfig.additionalCurrencies.map(value => <MenuItem key={value} value={value}>
                                    {value.toUpperCase()}
                                </MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Text
                            disabled={isDisabled}
                            field={{id: 'prefix', placeholder: "Одиниця розрахунку"}}
                            content={{prefix: content[field.id]?.prefix || undefined}}
                            onChange={onPrefixChange}
                        />
                    </Grid>
                </Grid>
            </Grid>}
        </>}

        {!flexiblePrice && !multiPrice && <Grid container spacing={1}>
            <Grid item xs={12}>
                <FormControlLabel control={<Switch checked={free} onChange={onFreeChange} />} label={"Безкоштовно"} />
            </Grid>
        </Grid>}
        
        {(!free && !flexiblePrice) && field?.options?.multi && <Grid container spacing={1}>
            <Grid item xs={12}>
                <FormControlLabel control={<Switch checked={multiPrice} onChange={onMultiChange} />} label={"Декілька цін"} />
            </Grid>
        </Grid>}

        {!free && !multiPrice && <Grid container spacing={1}>
            <Grid item xs={12}>
                <FormControlLabel control={<Switch checked={flexiblePrice} onChange={onFlexibleChange} />} label={"Гнучка заявки"} />
            </Grid>
        </Grid>}

        {flexiblePrice && <>
            <Grid container spacing={2} className='flexiblePricePlaceholder'>
                <Text
                    disabled={isDisabled}
                    field={{id: 'placeholder', placeholder: "Підпис"}}
                    content={content[field.id]}
                    onChange={onMultiPricePlaceholderChange}
                />
            </Grid>
            <Grid container spacing={2} className='flexiblePriceBlock'>
                <Grid item xs={12}>
                    <div>Користувач має заповнити форму, яка буде відправлена оператору</div>
                    <div className='flexiblePriceTable'>
                        {content[field.id]?.flexible && Object.keys(content[field.id]?.flexible)?.map(i =>
                            <Grid container spacing={1} key={`flexible_item_${i}`} className='flexiblePriceTableResult'>
                                <Grid item xs={10}>
                                    {content[field.id]?.flexible[i]}
                                </Grid>
                                <Grid item xs={2} className='removeOptionCell'>
                                    <ClearIcon className='removeOption' onClick={() => onRemoveFlexibleHandle(i)}/>
                                </Grid>
                            </Grid>
                        )}

                        <div className="inputEmulation">
                            <span className="inputLabel">Додати</span>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={10}>
                                    <Grid container>
                                        <Text
                                            disabled={isDisabled}
                                            field={{id: 'title', placeholder: "Назва"}}
                                            content={newFlexibleValue}
                                            onChange={onNewFlexibleChange}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        component="label"
                                        tabIndex={-1}
                                        onClick={onAddFlexibleHandle}
                                    >
                                        <AddIcon />
                                        {isMobile && <span>Додати</span>}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>}

        {(!free && !flexiblePrice) && <>
            {multiPrice && <>
                <Grid container spacing={2} className='multiPriceBlock'>
                    <Grid item xs={12} md={9}>
                        <Grid container>
                            <Text
                                disabled={isDisabled}
                                field={{id: 'placeholder', placeholder: "Підпис"}}
                                content={content[field.id]}
                                onChange={onMultiPricePlaceholderChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControl disabled={isDisabled} fullWidth>
                            <InputLabel>Валюта</InputLabel>
                            <Select
                                disabled={isDisabled}
                                label="Валюта"
                                value={content[field.id]?.currency || ''}
                                onChange={onCurrencyChange}>
                                    {cityConfig?.currency && <MenuItem key={cityConfig?.currency} value={cityConfig?.currency}>
                                        {cityConfig?.currency.toUpperCase()}
                                    </MenuItem>}
                                    {cityConfig.additionalCurrencies && cityConfig.additionalCurrencies.map(value => <MenuItem key={value} value={value}>
                                        {value.toUpperCase()}
                                    </MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Text
                                disabled={isDisabled}
                                field={{id: 'prefix', placeholder: "Одиниця розрахунку"}}
                                content={content[field.id]}
                                onChange={onMultiPricePrefixChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <div className='multiPriceTableTitle'>
                    <span className='label'>Ціни:</span>
                </div>
                <div className='multiPriceTable'>
                    {content[field.id]?.prices?.length > 0 && content[field.id]?.prices?.map(i =>
                        <Grid container spacing={1} key={`prices_item_${i.id}`} className='multiPriceTableResult'>
                            <Grid item xs={12} md={7}>
                                {i.title}
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {i.free ? 'Безкоштовно' :  <CurrencyFormated value={i.price} currency={content.price.currency} />}
                            </Grid>
                            <Grid item xs={12} md={1}>
                                {isMobile && <Button
                                    fullWidth
                                    // className={actionClassName}
                                    variant="outlined"
                                    component="label"
                                    tabIndex={-1}
                                    size='small'
                                    onClick={() => onRemoveMultiPriceHandle(i.id)}
                                >
                                    <ClearIcon />
                                    <span>Видалити</span>
                                </Button>}

                                {!isMobile && 
                                    <ClearIcon className='removeOption' onClick={() => onRemoveMultiPriceHandle(i.id)}/>
                                }
                            </Grid>
                        </Grid>
                    )}

                    <div className="inputEmulation">
                        <span className="inputLabel">Додати</span>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Text
                                        disabled={isDisabled}
                                        field={{id: 'title', placeholder: "Назва"}}
                                        content={multiPriceValue}
                                        onChange={onMultiPriceTitleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Number
                                    disabled={isDisabled || Boolean(multiPriceValue?.free)}
                                    field={{id: 'price', placeholder: "Ціна"}}
                                    content={multiPriceValue}
                                    onChange={onMultiPricePriceChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} className="verticalAlignCell">
                                <FormControlLabel control={<Switch checked={Boolean(multiPriceValue?.free)} onChange={onMultiPriceFreeChange} />} label={"Безкоштовно"} />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    fullWidth
                                    // className={actionClassName}
                                    variant="outlined"
                                    component="label"
                                    tabIndex={-1}
                                    onClick={onAddMultiPriceHandle}
                                >
                                    <AddIcon />
                                    {isMobile && <span>Додати</span>}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </>}
        </>}

        {field?.notes && <div className='fieldNotes'>
            <p>{field?.notes}</p>
        </div>}
        {showErrorText && <div className='fieldError'>
            {showErrorText}
        </div>}
    </div>
}
