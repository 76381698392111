import {isEqual} from 'lodash'
import {create} from 'zustand'
// import {getClientCity} from 'ultra/configs/general';
// import {DEFAULT_CITY} from 'ultra/const/general';

function getFieldsFromProduct(product) {
  // triggered from shopping card
  if (product.id) {
    return product;
  }

  const {_uri, title, price, options, addons, discount, thumbnail} = product
  
  return {
    id: product.did || product._did,
    _uri,
    title,
    price,
    options,
    addons,
    thumbnail,
    discount,
  }
}

function getFieldsFromShop(shop) {
  // triggered from shopping card so no need to be updated
  if (shop.id) {
    return shop;
  }

  if (!shop.partner || !shop.partner.config) return {}
  const result = {}

  const {orderForm, currency} = shop.partner.config;
  const title = shop.partner.title;

  result.partner = shop.partner.id;
  result.id = shop.partner.shop;

  if (title) result.title = title;
  if (currency) result.currency = currency;
  if (orderForm) result.orderForm = orderForm;

  if (shop.partner.city) result.city = shop.partner.city;
  if (shop.partner.link) result.link = shop.partner.link;

  return result;
}

export const useShoppingStore = create((set) => ({
  cart: JSON.parse(localStorage.getItem('cart')) || [],

  clearCard: () => set(() => {
    localStorage.setItem('cart', JSON.stringify([]))
    return {cart: []};
  }),

  addProduct: (product, shop, selectedPrice, options, addons, flexible) => set((state) => {
    const result = [...state.cart]
    result.push({
      product: getFieldsFromProduct(product),
      shop: getFieldsFromShop(shop),
      selectedPrice,
      options: options || {},
      addons: addons || [],
      flexible: flexible || {}
    })

    localStorage.setItem('cart', JSON.stringify(result))
    return {cart: result};
  }),

  updateProduct: (product, selectedPrice = null, options = null, addons = null, flexible = null, newSelectedPrice = null, newOptions = null, newAddons = null, newFlexible = null) => set((state) => {
    let cart = [...state.cart]

    const id = product.did || product._did || product.id

    const originalIndexes = []
    cart.map((product, i) => {
      if (product.product.id == id
        && product.selectedPrice == selectedPrice
        && isEqual(product.options, options)
        && isEqual(product.addons, addons)
      ) {
        originalIndexes.push(i);
      }
    })

    const newIndexes = []
    if (newFlexible) {
      cart.map((product, i) => {
        if (
          product.product.id == id
          && product.selectedPrice == newSelectedPrice
          && isEqual(product.flexible, newFlexible)
        ) {
          newIndexes.push(i)
        }
      })
    }
    else if (newOptions && !newAddons) {
      cart.map((product, i) => {
        if (
          product.product.id == id
          && product.selectedPrice == newSelectedPrice
          && isEqual(product.options, newOptions)
        ) {
          newIndexes.push(i)
        }
      })
    }
    else if (newAddons && !newOptions) {
      cart.map((product, i) => {
        if (
          product.product.id == id
          && product.selectedPrice == newSelectedPrice
          && isEqual(product.addons, newAddons)
        ) {
          newIndexes.push(i)
        }
      })
    }
    else {
      cart.map((product, i) => {
        if (
          product.product.id == id
          && product.selectedPrice == newSelectedPrice
          && isEqual(product.options, newOptions)
          && isEqual(product.addons, newAddons)
        ) {
          newIndexes.push(i)
        }
      })
    }

    const result = [...state.cart]

    // if already exist new options object
    if (newIndexes.length > 0) {
      newIndexes.map(i => {
        if (newSelectedPrice) result[i].product = newSelectedPrice
        if (newOptions) result[i].options = newOptions
        if (newAddons) result[i].addons = newAddons
        if (newFlexible) result[i].flexible = newFlexible
      })

      newIndexes.map(i => {
        result.splice(i, 1);
      })
    }
    // if not exist new options object
    else if (originalIndexes.length > 0) {
      originalIndexes.map(i => {
        if (newSelectedPrice) result[i].product = newSelectedPrice
        if (newOptions) result[i].options = newOptions
        if (newAddons) result[i].addons = newAddons
        if (newFlexible) result[i].flexible = newFlexible
      })
    }

    localStorage.setItem('cart', JSON.stringify(result))
    return {cart: result};
  }),

  removeProduct: (product, selectedPrice, options, addons, flexible) => set((state) => {
    let cart = [...state.cart]

    const id = product.did || product._did || product.id

    const productToRemoveIndex = cart.findIndex(i => ((
      i.product.id == id
      && i.selectedPrice == selectedPrice
      && isEqual(i.options, options)
      && isEqual(i.addons, addons)
      && isEqual(i.flexible, flexible)
    )))

    if (productToRemoveIndex > -1) {
      cart.splice(productToRemoveIndex, 1)
    }

    localStorage.setItem('cart', JSON.stringify(cart))
    return {cart}
  }),

  removeShop: (storeId, callback) => set((state) => {
    let cart = [...state.cart]
    const newCart = []
    cart.map((item) => {
      if (item.shop.id == storeId) return;
      newCart.push(item)
    })
    
    localStorage.setItem('cart', JSON.stringify(newCart))
    if (callback) callback(newCart)
    return {cart: newCart}
  }),

  removeAllProduct: (product) => set((state) => {
    let cart = [...state.cart]

    const id = product.did || product._did || product.id

    const updatedCart = cart.filter(i => ((i.product.id !== id)))

    localStorage.setItem('cart', JSON.stringify(updatedCart))
    return {cart: updatedCart}
  }),
}))
