import {isMobile} from "react-device-detect";

import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

import PreloaderTitleAndBreadcrumbs from '../PreloaderTitleAndBreadcrumbs'

export default function PreloaderShop(props) {
    const {hideBreadcrumbs} = props;

    return <div className='ShopProductsList'>
        {!hideBreadcrumbs && <PreloaderTitleAndBreadcrumbs />}

        <Grid container spacing={4}>
            <Grid item xs={12}></Grid>

            <Grid className="product" item xs={6} md={3}>
                <div className='photoWrap'>
                    <Skeleton variant="rectangular" width={240} height={isMobile ? 140 : 200} />
                </div>

                <div className="productTitle">
                    <Skeleton variant="rectangular" height={20} width={"70%"}/>
                </div>

                <div className="button">
                    <Skeleton variant="rectangular" height={50} width={"100%"}/>
                </div>
            </Grid>

            <Grid className="product" item xs={6} md={3}>
                <div className='photoWrap'>
                    <Skeleton variant="rectangular" width={240} height={isMobile ? 140 : 200} />
                </div>

                <div className="productTitle">
                    <Skeleton variant="rectangular" height={20} width={"70%"}/>
                </div>

                <div className="button">
                    <Skeleton variant="rectangular" height={50} width={"100%"}/>
                </div>
            </Grid>

            <Grid className="product" item xs={6} md={3}>
                <div className='photoWrap'>
                    <Skeleton variant="rectangular" width={240} height={isMobile ? 140 : 200} />
                </div>

                <div className="productTitle">
                    <Skeleton variant="rectangular" height={20} width={"70%"}/>
                </div>

                <div className="button">
                    <Skeleton variant="rectangular" height={50} width={"100%"}/>
                </div>
            </Grid>

            <Grid className="product" item xs={6} md={3}>
                <div className='photoWrap'>
                    <Skeleton variant="rectangular" width={240} height={isMobile ? 140 : 200} />
                </div>

                <div className="productTitle">
                    <Skeleton variant="rectangular" height={20} width={"70%"}/>
                </div>

                <div className="button">
                    <Skeleton variant="rectangular" height={50} width={"100%"}/>
                </div>
            </Grid>
        </Grid>
    </div>
}
